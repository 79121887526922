import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Paper,
  CircularProgress,
  colors,
  createFilterOptions,
} from '@mui/material'
import InputField from '../../components/common/InputField'
import InputTextarea from '../../components/common/InputTextArea'
import InputButton from '../../components/common/InputButton'
import { useFormik } from 'formik'
import * as yup from 'yup'
import DashboardLayout from '../../layouts/Dashboard'
import Popup from '../../components/common/Popup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import EditIcon from '@mui/icons-material/Edit'
import useStyles from '../../styles/DatePickerStyles'
import RiderBeneDetail from '../beneficiary/RiderBeneDetail'
import Autocomplete from '../../components/common/Autocomplete'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { DateTimePicker } from '@mui/x-date-pickers'
import GoogleMapComp from '../../components/common/GoogleMap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/action/loginActions'
import { setAccessToken } from '../../redux/action/tokenAction'
import { decryptData, encryptData } from '../../services/EncryptTemplate'

const validationSchema = yup.object({
  homeAddress: yup
    .string('Home address is required')
    .required('Home address is required'),
  deliveryDate: yup
    .date()
    .nullable('')
    .required('Date is required')
    .typeError('Date is required'),
  assignBranch: yup.mixed().required('Assign Branch is required'),
})
const RemittanceFormPage = () => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const search = window.location.search
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = new URLSearchParams(search)
  const id = params.get('id')
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(null)
  const classes = useStyles()
  const role = sessionStorage.getItem('role')
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [branchId, setBranchId] = useState(null)
  const [branchCode, setBranchCode] = useState(null)
  const [branchError, setBranchError] = useState(null)
  const [reqNum, setReqNum] = useState(null)
  const [pageLoader, setPageLoader] = useState(false)
  const [branches, setBranches] = useState([])
  const [data, setData] = useState()
  const [remittance, setRemittance] = useState()
  const [buttonClicked, setButtonClicked] = useState(false)
  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      if (res.status === 200) {
        res.data = decryptData(res?.data?.cipher)
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }
  const getBranch = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/branches`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)
      const data = response.data
      const modifiedArray = data?.data?.map(item => ({
        ...item,
        label: `${item.branchName} (${item.branchCode})`,
        branchName: item.branchName,
        branchCode: item.branchCode,
      }))

      const branchCodeArr = modifiedArray.map(item => item.branchCode)
      setBranchCode(branchCodeArr)
      setBranches(modifiedArray)

      return modifiedArray || []
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getBranch()
      }
      setSuccess(false)
      toast.error(error?.response?.data?.message || 'Error fetching branches')
    } finally {
      setLoading(false)
    }
  }
  const filterOptions = createFilterOptions({
    stringify: branchCode => `${branchCode.name} ${branchCode.code}`,
  })
  // const { data: branches } = useQuery({
  //   queryKey: ['branches'],
  //   queryFn: getBranch,
  // })

  const getRemittance = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/remittance/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        console.log(response, 'res')
        await getNewAccessToken()
        return getRemittance()
      }
      const data = response.data
      setRemittance(data?.data)
      setData(data?.data?.[0])

      setBranchId(data?.data?.[0]?.branchId)

      return data?.data?.[0] || {}
    } catch (error) {
      setSuccess(false)
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getRemittance()
      }
      toast.error(
        error?.response?.data?.message || 'Error fetching remittance data'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRemittance()
    getBranch()
  }, [])

  let edit = window.location.pathname.includes('inbox-detail')
  const makeApiCall = async (obj, isEdit = false) => {
    try {
      let url = `${process.env.REACT_APP_Url}/remittanceRequest`
      let method = 'post'
      let data = obj

      if (isEdit) {
        url += '/update'
        method = 'put'
      }
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios({
        method,
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        await getNewAccessToken()
        await makeApiCall(obj, isEdit)
      }
      if (response.status === 200 || response.status === 201) {
        setSuccess(true)
        if (isEdit) {
          setOpen(true)
          toast.success(response?.data?.message)
          navigate('/inbox')
        } else {
          setOpen(true)
          setReqNum(response?.data?.data?.requestId)
        }
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)

      if (error.response.status === 401) {
        await getNewAccessToken()
        await makeApiCall(obj, isEdit)
      } else {
        setSuccess(false)
        toast.error(error?.response?.data?.message)
      }
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
  }

  const formik = useFormik({
    initialValues: {
      homeAddress: '',
      deliveryDate: null,
      assignBranch: branchId,
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, error, errors) => {
      setButtonClicked(true)
      setLoadingButton('submit')
      const parsedDate = moment(values?.deliveryDate?.$d).format()
      let obj = {
        remittanceId: data?.remittanceId,
        assignBranch: branchId || data?.branchId,
        homeAddress: values?.homeAddress,
        deliveryDate: parsedDate,
        comment: values?.comment,
      }
      let editObj = {
        requestId: data?.id,
        comment: values?.comment,
        assignBranch: branchId || data?.branchId,
        homeAddress: values?.homeAddress,
        deliveryDate: parsedDate,
      }

      obj = encryptData(obj)
      editObj = encryptData(editObj)

      if (!branchId && !data?.branchId) {
      } else {
        if (data?.id && data?.requestStatus === 'revert') {
          await makeApiCall(editObj, true)
        } else {
          await makeApiCall(obj)
        }
      }
    },
  })

  const handleCharacterLimit = event => {
    const inputComment = event.target.value
    if (inputComment.length <= 150) {
      formik.handleChange(event)
    }
  }
  useEffect(() => {}, [formik.values.assignBranch])

  useEffect(() => {
    if (!data || !branches.length) {
      setPageLoader(true)
    } else {
      setPageLoader(false)
      formik.setFieldValue('transactionReference', data?.transactionReference)
      formik.setFieldValue('beneficiaryContact', data?.beneficiaryContact)
      formik.setFieldValue('remitterName', data?.remitterName)
      formik.setFieldValue('remitterAddress', data?.remitterAddress)
      formik.setFieldValue('beneficiaryName', data?.beneficiaryName)
      formik.setFieldValue('beneficiaryCnic', data?.beneficiaryCNIC)
      formik.setFieldValue('amount', data?.amount)
      formik.setFieldValue('dollarEquivalent', data?.dollarEquivalent)
      formik.setFieldValue('beneficiaryAccount', data?.beneficiaryAccount)
      formik.setFieldValue('beneficiaryBranchCode', data?.beneficiaryBranchCode)
      formik.setFieldValue('beneficiaryBranchAdd1', data?.beneficiaryBranchAdd1)
      formik.setFieldValue('beneficiaryBranchAdd2', data?.beneficiaryBranchAdd2)
      formik.setFieldValue('beneficiaryBranchAdd2', data?.beneficiaryBranchAdd2)
      formik.setFieldValue('assignBranch', data?.branchId)
      formik.setFieldValue('deliveryDate', dayjs(data?.deliveryDate))
      formik.setFieldValue('homeAddress', data?.homeAddress)
      formik.setFieldValue('requestID', data?.remittanceRequestId)
      formik.setFieldValue('firstName', data?.firstName + ' ' + data?.lastName)
      formik.setFieldValue(
        'exchangeCompanyAccount',
        data?.exchangeCompanyAccount
      )
      formik.setFieldValue('comment', data?.comment)
      formik.setFieldValue(
        'ultimateRemitterCountry',
        data?.ultimateRemitterCountry
      )
      setBranchId(data?.branchId)
    }
  }, [data, branches])

  const handleRevert = async () => {
    setButtonClicked(true)
    setLoadingButton('revert')
    let obj = {
      requestId: data?.id,
      comment: formik?.values?.comment,
    }
    obj = encryptData(obj)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.patch(
        `${process.env.REACT_APP_Url}/remittanceRequest/revert`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        console.log('res', response)
        await getNewAccessToken()
        return handleRevert()
      } else if (response.status === 200) {
        setSuccess(true)
        // setOpen(true)
        toast.success(response?.data?.message)
        navigate('/inbox')
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleRevert()
      }
      setSuccess(false)
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
  }
  const handleForward = async () => {
    setButtonClicked(true)
    setLoadingButton('forward')
    let obj = {
      requestId: data?.id,
      comment: formik?.values?.comment,
    }
    obj = encryptData(obj)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.patch(
        `${process.env.REACT_APP_Url}/remittanceRequest/forward`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        setSuccess(true)
        // setOpen(true)
        toast.success(response?.data?.message)

        navigate('/inbox')
      }
      if (response.status === 401) {
        await getNewAccessToken()
        return handleForward()
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleForward()
      }
      setSuccess(false)
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
  }
  const handleFundTransfered = async () => {
    setLoadingButton('fundTransfer')
    setButtonClicked(true)

    let fundObj = {
      remittanceId: data?.remittanceId,
    }
    fundObj = encryptData(fundObj)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Url}/remittance/fundTransfer`,
        fundObj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        setSuccess(true)
        // setOpen(true)
        toast.success(response?.data?.message)

        navigate('/inbox')
      }
      if (response.status === 401) {
        await getNewAccessToken()
        return handleFundTransfered()
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleFundTransfered()
      }
      setSuccess(false)
      toast.error(error?.response?.data?.message)
      // setButtonClicked(false)
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
  }

  useEffect(() => {
    if (branchId) {
      formik.setFieldValue('assignBranch', branchId)
    }
  }, [branchId])
  const remittanceData = remittance || []
  const hasValidCoordinates = remittanceData?.some(
    entry => entry?.latitude !== null && entry?.longitude !== null
  )
  return (
    <DashboardLayout>
      {open && (
        <Popup
          open={open}
          reqNum={reqNum}
          setOpen={setOpen}
          type={success ? 'Success' : 'Error'}
          detail={success ? 'Successful submission' : 'No Record Found'}
          move={true}
        />
      )}{' '}
      {pageLoader ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Paper
            sx={{
              borderRadius: '12px',
              paddingY: '40px',
              paddingX: '80px',
              marginTop: '40px',
            }}
          >
            <Box sx={{ m: '15px' }}>
              <Box>
                <Grid container className="ms-auto" spacing={4}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="transactionReference"
                      name="transactionReference"
                      type="text"
                      placeholder="transactionReference"
                      textLabel="Transaction Reference"
                      value={formik.values.transactionReference || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.transactionReference &&
                        Boolean(formik.errors.transactionReference)
                      }
                      helperText={
                        formik.touched.transactionReference &&
                        formik.errors.transactionReference
                      }
                    />
                  </Grid>
                  {role !== 'maker' && (
                    <Grid item xs={12} md={6}>
                      <InputField
                        id="requestID"
                        name="requestID"
                        type="text"
                        placeholder="Request Id"
                        textLabel="Request Id"
                        value={formik.values.requestID || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        error={
                          formik.touched.requestID &&
                          Boolean(formik.errors.requestID)
                        }
                        helperText={
                          formik.touched.requestID && formik.errors.requestID
                        }
                      />
                    </Grid>
                  )}
                  {role == 'checker' && (
                    <Grid item xs={12} md={6}>
                      <InputField
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="Maker"
                        textLabel="Maker"
                        value={formik.values.firstName || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </Grid>
                  )}
                  {role == 'maker' && (
                    <Grid item xs={12} md={6}>
                      <InputField
                        id="exchangeCompanyAccount"
                        name="exchangeCompanyAccount"
                        type="text"
                        placeholder="Exchange Company Account"
                        textLabel="Exchange Company Account"
                        value={formik.values.exchangeCompanyAccount || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        error={
                          formik.touched.exchangeCompanyAccount &&
                          Boolean(formik.errors.exchangeCompanyAccount)
                        }
                        helperText={
                          formik.touched.exchangeCompanyAccount &&
                          formik.errors.exchangeCompanyAccount
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryContact"
                      name="beneficiaryContact"
                      type="text"
                      placeholder="Beneficiary Contact"
                      textLabel="Beneficiary Contact"
                      value={formik.values.beneficiaryContact || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryContact &&
                        Boolean(formik.errors.beneficiaryContact)
                      }
                      helperText={
                        formik.touched.beneficiaryContact &&
                        formik.errors.beneficiaryContact
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="remitterName"
                      name="remitterName"
                      type="text"
                      placeholder="Remitter Name"
                      textLabel="Remitter Name"
                      value={formik.values.remitterName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.remitterName &&
                        Boolean(formik.errors.remitterName)
                      }
                      helperText={
                        formik.touched.remitterName &&
                        formik.errors.remitterName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="remitterAddress"
                      name="remitterAddress"
                      type="text"
                      placeholder="Remitter Address"
                      textLabel="Remitter Address"
                      value={formik.values.remitterAddress || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.remitterAddress &&
                        Boolean(formik.errors.remitterAddress)
                      }
                      helperText={
                        formik.touched.remitterAddress &&
                        formik.errors.remitterAddress
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryName"
                      name="beneficiaryName"
                      type="text"
                      placeholder="Beneficiary Name"
                      textLabel="Beneficiary Name"
                      value={formik.values.beneficiaryName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryName &&
                        Boolean(formik.errors.beneficiaryName)
                      }
                      helperText={
                        formik.touched.beneficiaryName &&
                        formik.errors.beneficiaryName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryCnic"
                      name="beneficiaryCnic"
                      type="text"
                      placeholder="Beneficiary CNIC"
                      textLabel="Beneficiary CNIC"
                      value={formik.values.beneficiaryCnic || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryCnic &&
                        Boolean(formik.errors.beneficiaryCnic)
                      }
                      helperText={
                        formik.touched.beneficiaryCnic &&
                        formik.errors.beneficiaryCnic
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="amount"
                      name="amount"
                      type="text"
                      placeholder="Amount"
                      textLabel="Amount"
                      value={formik.values.amount || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="dollarEquivalent"
                      name="dollarEquivalent"
                      type="text"
                      placeholder="Dollar Equivalent"
                      textLabel="Dollar equivalent"
                      value={formik.values.dollarEquivalent || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.dollarEquivalent &&
                        Boolean(formik.errors.dollarEquivalent)
                      }
                      helperText={
                        formik.touched.dollarEquivalent &&
                        formik.errors.dollarEquivalent
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryAccount"
                      name="beneficiaryAccount"
                      type="text"
                      placeholder="Beneficiary Account"
                      textLabel="Beneficiary account"
                      value={formik.values.beneficiaryAccount || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryAccount &&
                        Boolean(formik.errors.beneficiaryAccount)
                      }
                      helperText={
                        formik.touched.beneficiaryAccount &&
                        formik.errors.beneficiaryAccount
                      }
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryBranchCode"
                      name="beneficiaryBranchCode"
                      type="text"
                      placeholder="Beneficiary branch code"
                      textLabel="Beneficiary branch code"
                      value={formik.values.beneficiaryBranchCode || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryBranchCode &&
                        Boolean(formik.errors.beneficiaryBranchCode)
                      }
                      helperText={
                        formik.touched.beneficiaryBranchCode &&
                        formik.errors.beneficiaryBranchCode
                      }
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryBranchAdd1"
                      name="beneficiaryBranchAdd1"
                      type="text"
                      placeholder="Beneficiary Branch Add 1 "
                      textLabel="Beneficiary Branch Add 1 (City region)"
                      value={formik.values.beneficiaryBranchAdd1 || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryBranchAdd1 &&
                        Boolean(formik.errors.beneficiaryBranchAdd1)
                      }
                      helperText={
                        formik.touched.beneficiaryBranchAdd1 &&
                        formik.errors.beneficiaryBranchAdd1
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="beneficiaryBranchAdd2"
                      name="beneficiaryBranchAdd2"
                      type="text"
                      placeholder="Beneficiary Branch Add 2 "
                      textLabel="Beneficiary Branch Add 2 "
                      value={formik.values.beneficiaryBranchAdd2 || ''}
                      onChange={formik.handleChange}
                      disabled={true}
                      error={
                        formik.touched.beneficiaryBranchAdd2 &&
                        Boolean(formik.errors.beneficiaryBranchAdd2)
                      }
                      helperText={
                        formik.touched.beneficiaryBranchAdd2 &&
                        formik.errors.beneficiaryBranchAdd2
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="homeAddress"
                      name="homeAddress"
                      type="text"
                      placeholder="Home Address"
                      textLabel="Home Address"
                      value={formik.values.homeAddress || ''}
                      onChange={handleCharacterLimit}
                      onBlur={formik.handleBlur}
                      icon={role === 'maker' && 'editIcon'}
                      disabled={
                        role !== 'maker'
                          ? true
                          : false || data?.fundTransferred !== 1
                          ? true
                          : false
                      }
                      error={
                        formik.touched.homeAddress &&
                        Boolean(formik.errors.homeAddress)
                      }
                      helperText={
                        formik.touched.homeAddress && formik.errors.homeAddress
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      id="ultimateRemitterCountry"
                      name="ultimateRemitterCountry"
                      type="text"
                      placeholder="Ultimate Remitter Country"
                      textLabel="Ultimate Remitter Country"
                      value={formik.values.ultimateRemitterCountry || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={true}
                      error={
                        formik.touched.ultimateRemitterCountry &&
                        Boolean(formik.errors.ultimateRemitterCountry)
                      }
                      helperText={
                        formik.touched.ultimateRemitterCountry &&
                        formik.errors.ultimateRemitterCountry
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Box display={'flex'} alignItems={'center'}>
                      <label
                        style={{
                          fontSize: '12px',
                          marginLeft: '10px',
                          marginBottom: '4px',
                          color: '#074F93',
                        }}
                      >
                        Delivery Date
                      </label>
                      {role === 'maker' && (
                        <Box ml="5px">
                          <EditIcon style={{ width: '15px' }} />
                        </Box>
                      )}
                    </Box>
                    <LocalizationProvider
                      className={classes.inputField}
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        className={classes.inputField}
                        // className={`${classes.inputField} ${classes.datePicker}`}
                        dateAdapter={AdapterDayjs}
                        disabled={
                          role !== 'maker'
                            ? true
                            : false || data?.fundTransferred !== 1
                            ? true
                            : false
                        }
                        id="deliveryDate"
                        name="deliveryDate"
                        minDate={dayjs()}
                        value={formik?.values?.deliveryDate}
                        onChange={date =>
                          formik.setFieldValue('deliveryDate', date)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.deliveryDate &&
                          Boolean(formik.errors.deliveryDate) &&
                          !formik.values.deliveryDate // Show error only if the date is not selected
                        }
                        helperText={
                          formik.touched.deliveryDate &&
                          formik.errors.deliveryDate &&
                          !formik.values.deliveryDate // Show helper text only if the date is not selected
                            ? formik.errors.deliveryDate
                            : ''
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </LocalizationProvider>

                    <Box
                      sx={{
                        fontSize: ' 0.75rem;',
                        color: '#d32f2f',
                        fontWeight: '400',
                      }}
                      marginLeft={'10px'}
                    >
                      {' '}
                      {formik.errors.deliveryDate &&
                        formik.touched.deliveryDate &&
                        formik.errors.deliveryDate}
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Box display={'flex'} alignItems={'center'}>
                      <label
                        style={{
                          fontSize: '12px',
                          marginLeft: '10px',
                          marginBottom: '4px',
                          color: '#074F93',
                        }}
                      >
                        Delivery Date and Time
                      </label>
                      {role === 'maker' && (
                        <Box ml="5px">
                          <EditIcon style={{ width: '15px' }} />
                        </Box>
                      )}
                    </Box>
                    <LocalizationProvider
                      className={classes.inputField}
                      dateAdapter={AdapterDayjs}
                    >
                      <DateTimePicker
                        className={classes.inputField}
                        dateAdapter={AdapterDayjs}
                        disabled={
                          role !== 'maker'
                            ? true
                            : false || data?.fundTransferred !== 1
                            ? true
                            : false
                        }
                        id="deliveryDate"
                        name="deliveryDate"
                        minDate={dayjs()}
                        value={formik?.values?.deliveryDate}
                        onChange={date =>
                          formik.setFieldValue('deliveryDate', date)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.deliveryDate &&
                          Boolean(formik.errors.deliveryDate) &&
                          !formik.values.deliveryDate
                            ? formik.errors.deliveryDate
                            : undefined
                        }
                        helperText={
                          formik.touched.deliveryDate &&
                          formik.errors.deliveryDate &&
                          !formik.values.deliveryDate // Show helper text only if the date is not selected
                            ? formik.errors.deliveryDate
                            : ''
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </LocalizationProvider>
                    <Box
                      sx={{
                        fontSize: ' 0.75rem;',
                        color: '#d32f2f',
                        fontWeight: '400',
                      }}
                      marginLeft={'10px'}
                    >
                      {' '}
                      {formik.errors.deliveryDate &&
                        formik.touched.deliveryDate &&
                        formik.errors.deliveryDate}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      icon={role === 'maker' && 'editIcon'}
                      disabled={
                        role !== 'maker'
                          ? true
                          : false || data?.fundTransferred !== 1
                          ? true
                          : false
                      }
                      id="assignBranch"
                      name="assignBranch"
                      options={branches}
                      edit={edit}
                      sx={{ width: 300, borderRadius: '20px' }}
                      textLabel=" Assigned Branch"
                      data={branches}
                      setBranchError={setBranchError}
                      filterOptions={filterOptions}
                      // setBranchId={setBranchId}
                      setBranchId={id => {
                        formik.setFieldValue('assignBranch', id)
                        setBranchId(id)
                      }}
                      value={formik.values.assignBranch || branchId || ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.assignBranch &&
                        Boolean(formik.errors.assignBranch)
                      }
                      editPage={true}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.assignBranch &&
                        formik.errors.assignBranch &&
                        !formik.values.assignBranch // Show helper text only if the date is not selected
                          ? formik.errors.assignBranch
                          : ''
                      }
                    />
                    <Box
                      sx={{
                        fontSize: ' 0.75rem;',
                        color: '#d32f2f',
                        fontWeight: '400',
                        marginTop: '5px',
                        marginLeft: '10px',
                      }}
                    >
                      {formik.touched.assignBranch &&
                        formik.errors.assignBranch &&
                        formik.errors.assignBranch}
                      {branchError}
                    </Box>
                  </Grid>
                  {(role === 'checker' || role === 'maker' || data?.id) && (
                    <Grid item xs={12}>
                      {console.log(
                        role != 'teller'
                          ? false
                          : (role == 'teller' && data?.riderReqeustStatus) ===
                            'assignToRider'
                          ? false
                          : true
                      )}
                      <InputTextarea
                        id="comment"
                        name="comment"
                        label="Comment"
                        placeholder="Comment"
                        textLabel=" Comment "
                        icon={role === 'checker' ? 'editIcon' : ''}
                        disabled={
                          role != 'teller'
                            ? false
                            : role == 'teller' &&
                              data?.riderReqeustStatus == 'assignToRider'
                            ? false
                            : true
                        }
                        value={formik?.values?.comment || ''}
                        onChange={handleCharacterLimit}
                        minRows={3}
                      />
                    </Grid>
                  )}
                  {role === 'checker' ? (
                    <Grid
                      container
                      align="center"
                      justify="center"
                      marginY={'30px'}
                    >
                      <Grid item xs={12}>
                        <InputButton
                          size={'small'}
                          loading={loadingButton === 'forward'}
                          sx={{
                            marginTop: '0',
                            padding: '0.5rem 2rem',
                            marginRight: '12px',
                          }}
                          disabled={buttonClicked}
                          onClick={handleForward}
                          label="Forward"
                        />
                        <InputButton
                          size={'small'}
                          sx={{
                            marginTop: '0',
                            padding: '0.5rem 2rem',
                            marginRight: '12px',
                            backgroundColor: 'red',
                          }}
                          loading={loadingButton === 'revert'}
                          disabled={buttonClicked}
                          onClick={handleRevert}
                          label="Revert"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      align="center"
                      justify="center"
                      marginY={'30px'}
                    >
                      {role !== 'superAdmin' &&
                        (role !== 'teller' && data?.fundTransferred !== 1 ? (
                          <Grid item xs={12}>
                            <InputButton
                              size={'small'}
                              loading={loadingButton === 'fundTransfer'}
                              disabled={buttonClicked}
                              sx={{
                                marginTop: '0',
                                padding: '0.5rem 2rem',
                                marginRight: '12px',
                              }}
                              label="Fund Transfer"
                              onClick={handleFundTransfered}
                            />
                          </Grid>
                        ) : (
                          (data?.requestStatus === 'revert' ||
                            data?.requestStatus === null ||
                            data?.requestStatus === 'revertedByTeller') && (
                            <Grid item xs={12}>
                              <InputButton
                                size={'small'}
                                loading={loadingButton === 'submit'}
                                disabled={
                                  buttonClicked ||
                                  (data?.requestStatus === 'revertedByTeller' &&
                                    data?.attempt === 3)
                                    ? true
                                    : false
                                }
                                sx={{
                                  marginTop: '0',
                                  padding: '0.5rem 2rem',
                                  marginRight: '12px',
                                }}
                                type="submit"
                                label="Submit"
                              />
                            </Grid>
                          )
                        ))}
                    </Grid>
                  )}
                  {/* {role === 'checker' ? (
                    <>
                      <Grid
                        container
                        align="center"
                        justify="center"
                        marginY={'30px'}
                      >
                        <Grid item xs={12}>
                          <InputButton
                            size={'small'}
                            loading={loading}
                            sx={{
                              marginTop: '0',
                              padding: '0.5rem 2rem',
                              marginRight: '12px',
                            }}
                            disabled={buttonClicked}
                            onClick={handleForward}
                            label="Forward"
                          />
                          <InputButton
                            size={'small'}
                            sx={{
                              marginTop: '0',
                              padding: '0.5rem 2rem',
                              marginRight: '12px',
                              backgroundColor: 'red',
                            }}
                            loading={loading}
                            disabled={buttonClicked}
                            onClick={handleRevert}
                            label="Revert"
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      container
                      align="center"
                      justify="center"
                      marginY={'30px'}
                    >
                      {role !== 'superAdmin' &&
                        (role !== 'teller' && data?.fundTransferred !== 1 ? (
                          <Grid item xs={12}>
                            <InputButton
                              size={'small'}
                              loading={loading}
                              disabled={buttonClicked}
                              sx={{
                                marginTop: '0',
                                padding: '0.5rem 2rem',
                                marginRight: '12px',
                              }}
                              label="Fund Transfer"
                              onClick={handleFundTransfered}
                            />
                          </Grid>
                        ) : (
                          (data?.requestStatus === 'revert' ||
                            data?.requestStatus === null ||
                            data?.requestStatus === 'revertedByTeller') && (
                            <Grid item xs={12}>
                              <InputButton
                                size={'small'}
                                loading={loading}
                                disabled={
                                  buttonClicked ||
                                  (data?.requestStatus === 'revertedByTeller' &&
                                    data?.attempt === 3)
                                    ? true
                                    : false
                                }
                                sx={{
                                  marginTop: '0',
                                  padding: '0.5rem 2rem',
                                  marginRight: '12px',
                                }}
                                type="submit"
                                label="Submit"
                              />
                            </Grid>
                          ) 
                        ))}*/}

                  {/* {role !== 'teller' &&
                        (data?.requestStatus === 'revert' ||
                          data?.requestStatus === null ||
                          data?.requestStatus === 'revertedByTeller') && (
                          <Grid item xs={12}>
                            <InputButton
                              size={'small'}
                              loading={loading}
                              disabled={buttonClicked}
                              sx={{
                                marginTop: '0',
                                padding: '0.5rem 2rem',
                                marginRight: '12px',
                              }}
                              type="submit"
                              label="Submit"
                            />
                          </Grid>
                        )} */}
                  {/* </Grid>
                  )} */}
                </Grid>
              </Box>
            </Box>
          </Paper>
        </form>
      )}
      {role === 'teller' && (
        <RiderBeneDetail
          comment={formik?.values?.comment}
          remittanceData={data}
        />
      )}
      {hasValidCoordinates && <GoogleMapComp remittanceData={remittanceData} />}
    </DashboardLayout>
  )
}
export default RemittanceFormPage
