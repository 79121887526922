import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  inputField: {
    '&.MuiTextField-root': {
      marginBottom: '2rem',
      '& .MuiInputBase-root': {
        borderRadius: '40px',
        fontSize: '1rem',
        height: '3.5rem',
        fontWeight: 'bold',
        padding: '0.5em 1em',
        '& .MuiInputBase-input': {
          fontSize: '0.8rem',
          height: 'auto',
          fontWeight: '500',
          color: theme.palette.text.primary,
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px transparent inset',
            transition: 'background-color 5000s ease-in-out 0s',
          },
          '&:-ms-input-placeholder': {
            backgroundColor: `transparent`,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid #C5C5C5`,
          '& legend': {
            marginLeft: '1rem',
          },
        },
      },
      '& .MuiInputLabel-root': {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '2.5rem',
        transform: 'translate(1.5rem, 0.5rem) scale(1)',
        '&.MuiInputLabel-shrink': {
          left: '0.9rem',
          top: '-0.45rem',
          transform: 'translate(1.1rem, -0.5rem) scale(0.75)',
        },
      },
      '& .MuiIconButton-root': {
        '& .MuiSvgIcon-root': {
          fill: theme.palette.colorsName.darkSilver,
        },
      },
    },
  },
}))

export default useStyles
