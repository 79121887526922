// src/routers/index.js
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../pages/Login'
import User from '../pages/user/User'
import Error from '../pages/Error'
import Inbox from '../pages/inbox/Inbox'
import Reports from '../pages/Reporting/Reports'
import RemittanceFormPage from '../pages/remittanceForm/RemittanceFormPage'
import AddUser from '../pages/user/AddUser'
import SearchInbox from '../pages/inbox/SearchInbox'
import { useSelector } from 'react-redux'
import UpdatePassword from '../pages/UpdatePassword'

const AppRouter = () => {
  const login =  sessionStorage.getItem('role')
  const { user } = useSelector((state) => state.login);
  return (
    // <>
    //   {( sessionStorage.getItem("login") === null ||  sessionStorage.getItem("login") === undefined) ?
    //     (<Routes>
    //       <Route path="*" element={<Login />} />
    //     </Routes>)
    //     :

    //     (
    //       <>


    //         <Routes>
    //           <Route path="/inbox" element={<Inbox />} />
    //           <Route path="/inbox-detail" element={<RemittanceFormPage />} />
    //           <Route path="/user" element={<User />} />
    //           <Route path="/reporting" element={<Reports />} />
    //           <Route path="/search_request" element={<SearchInbox />} />
    //           <Route path="/add-user" element={<AddUser />} />
    //           <Route path="/add-user/:id" element={<AddUser />} />
    //           <Route path="/edit-user/:id" element={<AddUser />} />

    //         </Routes>

    //       </>


    //     )
    //   }
    // </>
    <Routes>

      {user ? <>
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/inbox-detail" element={<RemittanceFormPage />} />
        <Route path="/user" element={<User />} />
        <Route path="/reporting" element={<Reports />} />
        <Route path="/search_request" element={<SearchInbox />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/add-user/:id" element={<AddUser />} />
        <Route path="/edit-user/:id" element={<AddUser />} /></> : <>
        <Route path="*" element={<Login />} />
      </>}

    </Routes>


// {user ? (
//   <>
//     <Route path="/inbox" element={<Inbox />} />
//     <Route path="/inbox-detail" element={<RemittanceFormPage />} />
//     <Route path="/user" element={<User />} />
//     {user.updatePassword === 1 && <Route path="/updatePassword" element={<UpdatePassword />} />}
//     <Route path="/reporting" element={<Reports />} />
//     <Route path="/search_request" element={<SearchInbox />} />
//     <Route path="/add-user" element={<AddUser />} />
//     <Route path="/add-user/:id" element={<AddUser />} />
//     <Route path="/edit-user/:id" element={<AddUser />} />
//     <Route path="/login" element={<Login />} />
//   </>
// ) : (
//   <>
//     <Route path="*" element={<Login />} />
//   </>
// )}
    // <Routes>
    //   <Route path="/" element={<Login />} />
    //   <Route path="*" element={<Error />} />

    //   { sessionStorage.getItem('login') === null ||
    //    sessionStorage.getItem('login') === undefined ? (
    //     <Route path="/" component={Login} />
    //   ) : (
    //     <>
    //       <Route path="/inbox" element={<Inbox />} />
    //       <Route path="/inbox-detail" element={<RemittanceFormPage />} />
    //       <Route path="/user" element={<User />} />
    //       <Route path="/reporting" element={<Reports />} />
    //       <Route path="/search_request" element={<SearchInbox />} />
    //       <Route path="/add-user" element={<AddUser />} />
    //       <Route path="/add-user/:id" element={<AddUser />} />
    //       <Route path="/edit-user/:id" element={<AddUser />} />
    //     </>
    //   )}
    // </Routes>
  )
}

export default AppRouter