import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REMOVE_USER_DATA,
  STORE_USER_DATA,
} from '../action/loginActions'
import { ACCESS_TOKEN } from '../action/tokenAction';

const initialState = {

  user: sessionStorage?.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : null,
  // user:null,
  accessToken: sessionStorage.getItem('token') || null, // Initialize with token from sessionStorage
  refreshToken: sessionStorage.getItem('refreshToken') || null,
  loginLoading: false,
  error: null,
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
        error: null,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        accessToken:  action.payload.accessToken,
        refreshToken:  action.payload.refreshToken,
        loginLoading: false,
        error: null,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        error: action.payload,
      }
    case LOGOUT:
      sessionStorage.clear()
      return {
        user: null,
        loginLoading: false,
        error: null,
      }
    case STORE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      }

    case REMOVE_USER_DATA:
      return {
        ...state,
        user: null,
      }

    default:
      return state
  }
}

export default loginReducer
