import * as React from 'react'
import Dashboard from '../components/dashboard/Main'

const DashboardLayout = ({ children, pageName }) => {
  return (
    <>
      <Dashboard pageName={pageName}>{children}</Dashboard>
    </>
  )
}

export default DashboardLayout
