import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material'
import sidebarLogo from '../../assets/bank-logo.svg'
import userIcon from '../../assets/user-icon.svg'
import inboxIcon from '../../assets/inbox-icon.svg'
import searchIcon from '../../assets/search-request-icon.svg'
import reportIcon from '../../assets/report-icon.svg'
import useStyles from '../../styles/SideBarStyles'

const Sidebar = ({ open }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const role =  sessionStorage.getItem('role')
  function capitalizeFirstLetter(str) {
    if (str?.length === 0) {
      return str
    } else {
      return str?.charAt(0).toUpperCase() + str?.slice(1)
    }
  }

  function capitalizeFirstLetter(str) {
    if (str?.length === 0) {
        return str;
    } else {
        let modifiedStr = str.charAt(0).toUpperCase() + str.slice(1);
        modifiedStr = modifiedStr.replace(/([a-z])([A-Z])/g, '$1 $2');
        
        return modifiedStr;
    }
}
  return (
    <Drawer
      className={classes.sideBar}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <List>
        <ListItem className={classes.sideBarLogoList} disablePadding>
          <ListItemButton onClick={() => navigate('/')}>
            <Avatar alt="Sidebar logo" src={sidebarLogo} />
          </ListItemButton>
        </ListItem>
      </List>
      <List className={classes.sideBarMenu}>
        <ListItem disablePadding>
          <ListItemButton
          // onClick={() => navigate('/home')}
          >
            <ListItemIcon>
              <Avatar alt="Dashboard Icon" src={userIcon} />
            </ListItemIcon>
            <ListItemText primary={capitalizeFirstLetter(role)} />
          </ListItemButton>
        </ListItem>
        {role === 'superAdmin' && (
          <>
            {' '}
            <ListItem>
              <ListItemButton onClick={() => navigate('/user')}>
                <ListItemIcon>
                  <Avatar alt="user Icon" src={userIcon} />
                </ListItemIcon>
                <ListItemText primary="User Management" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate('/add-user')}>
                <ListItemIcon>
                  <Avatar alt="user Icon" src={userIcon} />
                </ListItemIcon>
                <ListItemText primary="Add user" />
              </ListItemButton>
            </ListItem>
          </>
        )}
        {role !== 'superAdmin' && (
          <ListItem>
            <ListItemButton onClick={() => navigate('/inbox')}>
              <ListItemIcon>
                <Avatar alt="Inbox Icon" src={inboxIcon} />
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </ListItemButton>
          </ListItem>
        )}

        {/* {role !== 'superAdmin' && ( */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/search_request')}>
              <ListItemIcon>
                <Avatar alt="Search Icon" src={searchIcon} />
              </ListItemIcon>
              <ListItemText primary="Search Request" />
            </ListItemButton>
          </ListItem>
        {/* )} */}

        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/reporting')}>
            <ListItemIcon>
              <Avatar alt="Reporting Icon" src={reportIcon} />
            </ListItemIcon>
            <ListItemText primary="Reporting" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

export default Sidebar
