import { ACCESS_TOKEN, REFRESH_TOKEN } from "../action/tokenAction";


const initialState = {
  accessToken: null,
  refreshToken: null,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    default:
      return state;
  }
};

export default tokenReducer;
