import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  inputButton: {
    '&.MuiButton-root': {
      '&.MuiButton-containedPrimary': {
        borderRadius: '45px',
        // padding: '0.5rem 1.5rem',
        fontSize: '0.9rem',
        fontWeight: '500',
      },
    },
  },
}))

export default useStyles
