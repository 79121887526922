import { makeStyles } from '@mui/styles'
import loginScreenBg from '../assets/login-screen-bg.png'

const useStyles = makeStyles(theme => ({
  loginScreen: {
    '&.MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      backgroundImage: `url(${loginScreenBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  loginBankLogo: {
    '&.MuiAvatar-root': {
      display: 'block',
      borderRadius: 0,
      width: '12.5rem',
      height: '2.5rem',
      position: 'absolute',
      top: 20,
      fontSize: 0,
      lineHeight: 0,
      '& .MuiAvatar-img': {
        objectFit: 'contain',
      },
    },
  },
  loginInnerScreen: {
    '&.MuiPaper-root': {
      // padding: `1rem 6rem 3rem 6rem`,
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: `2rem`,
      },
      [theme.breakpoints.down('sm')]: {
        padding: `1rem`,
      },
      [theme.breakpoints.up('md')]: {
        padding: `1rem 6rem 3rem 6rem`,
      },
    },
  },
  mainHeading: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },
  },
  loginIcon: {
    '&.MuiAvatar-root': {
      width: '150px',
      height: '150px',
      position: 'absolute',
      top: '-80px',
      left: 'calc(50% - 75px)',
      [theme.breakpoints.down('md')]: {
        width: '75px',
        height: '75px',
        top: '-40px',
        left: 'calc(50% - 37.5px)',
      },
    },
  },
}))

export default useStyles
