import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  dashboardMain: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
  },
  dashboardMainOpen: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))

export default useStyles
