import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Grid, Paper, Stack } from '@mui/material'
import InputField from '../../components/common/InputField'
import InputButton from '../../components/common/InputButton'
import { useFormik } from 'formik'
import ImgIcon from '../../assets/profile_photo.jpg'
import cnincFront from '../../assets/cnic front.png'
import cnicBack from '../../assets/cnic back.png'
import Popup from '../../components/common/Popup'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import PrintableContainer from './PrintableComponent'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/action/loginActions'
import { setAccessToken } from '../../redux/action/tokenAction'
import { decryptData, encryptData } from '../../services/EncryptTemplate'
const validationSchema = yup.object({
  // beneficiaryName: yup.string('Enter beneficiary name').required('Required'),
})

const Detail = ({ remittanceData, comment }) => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(null)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [assignAgain, setAssignAgain] = useState(false)
  const [isSignoffClicked, setIsSignoffClicked] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [data, setData] = useState()
  const navigate = useNavigate()
  const printRef = useRef()
  const dispatch = useDispatch()
  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      res.data = decryptData(res?.data?.cipher)
      if (res.status === 200) {
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }
  const getRiderByBranch = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      if (remittanceData?.branchId) {
        const response = await axios.get(
          `${process.env.REACT_APP_Url}/user/riderByBranch/${remittanceData?.branchId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        response.data = decryptData(response?.data?.cipher)
        if (response.status === 401) {
          await getNewAccessToken()
          if (accessToken) return getRiderByBranch()
        }

        const data = response.data
        setData(data?.data || '')
        return data?.data || ''
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        if (accessToken) return getRiderByBranch()
      }

      console.error('Error fetching riders:', error)
    }
  }

  useEffect(() => {
    getRiderByBranch()
  }, [remittanceData])

  const makeRiderRequest = async obj => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Url}/riderRequest`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)

      if (response.status === 201) {
        setOpen(true)
        toast.success(response?.data?.message)
        setSuccess(true)
      }
      if (response.status === 401) {
        await getNewAccessToken()
        if (accessToken) await makeRiderRequest(obj)
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)

      if (error.response.status === 401) {
        await getNewAccessToken()
        if (accessToken) await makeRiderRequest(obj)
      } else {
        setSuccess(false)
        toast.error(error?.response?.data?.message)
      }
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
  }

  useEffect(() => {
    formik.setFieldValue(
      'riderName',
      (data?.firstName ? data?.firstName : '') +
        ' ' +
        (data?.lastName ? data?.lastName : '') || ''
    )
    formik.setFieldValue('riderContactNumber', data?.phone || '')
    formik.setFieldValue('riderCNIC', data?.cnic || '')
    formik.setFieldValue('comment', data?.comment || '')
  }, [data])

  const formik = useFormik({
    initialValues: {
      beneficiaryCnic: '',
      riderName: '',
      riderContactNumber: '',
      riderCNIC: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setButtonClicked(true)
      setLoadingButton('submit')

      let obj = {
        remittanceRequest: remittanceData?.id,
        remittance: remittanceData?.remittanceId,
        branch: remittanceData?.branchId,
        rider: data?.id,
      }
      obj = encryptData(obj)

      await makeRiderRequest(obj)
    },
  })

  const handleHandOvertoRider = async () => {
    setButtonClicked(true)
    setLoadingButton('handOverToRider')
    let obj = {
      id: remittanceData?.riderReqeustId,
    }
    obj = encryptData(obj)
    if (remittanceData?.riderReqeustId) {
      try {
        if (!accessToken) {
          dispatch(logout())
          navigate('/login')
          return
        }
        const response = await axios.patch(
          `${process.env.REACT_APP_Url}/riderRequest/handedOverToRider`,
          obj,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        response.data = decryptData(response?.data?.cipher)

        if (response.status === 200) {
          setOpen(true)
          toast.success(response?.data?.message)
          setSuccess(true)
          setLoadingButton('handOverToRider')
        }
        if (response.status === 401) {
          await getNewAccessToken()
          return handleHandOvertoRider()
        }
      } catch (error) {
        error.response.data = decryptData(error.response?.data?.cipher)
        if (error.response.status === 401) {
          await getNewAccessToken()
          return handleHandOvertoRider()
        }
        setSuccess(false)
        toast.error(error?.response?.data?.message)
      } finally {
        setLoadingButton(null)
      }
      setButtonClicked(false)
    }
  }
  const handleRevertedByMaker = async () => {
    setButtonClicked(true)
    setLoadingButton('revertedByMaker')
    let obj = {
      riderRequestIds: [remittanceData?.riderReqeustId],
      comment: comment || null,
    }
    obj = encryptData(obj)
    // if (remittanceData?.riderReqeustId) {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.patch(
        `${process.env.REACT_APP_Url}/riderRequest/revertedByTeller`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        setOpen(true)
        toast.success(response?.data?.message)
        setSuccess(true)
        setLoadingButton('revertedByMaker')
      }
      if (response.status === 401) {
        await getNewAccessToken()
        return handleRevertedByMaker()
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleRevertedByMaker()
      }
      setSuccess(false)
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingButton(null)
    }
    setButtonClicked(false)
    // }
  }
  const handleCashReceived = async () => {
    setButtonClicked(true)
    setLoadingButton('cashReceived')
    let obj = {
      riderRequestIds: [remittanceData?.riderReqeustId],
    }
    obj = encryptData(obj)
    if (remittanceData?.riderReqeustId) {
      try {
        if (!accessToken) {
          dispatch(logout())
          navigate('/login')
          return
        }
        const response = await axios.patch(
          `${process.env.REACT_APP_Url}/riderRequest/cashReceivedBack`,
          obj,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        response.data = decryptData(response?.data?.cipher)

        if (response.status === 200) {
          toast.success(response?.data?.message)
          setAssignAgain(false)
          setLoadingButton('cashReceived')
          navigate('/inbox')
        }
        if (response.status === 401) {
          await getNewAccessToken()
          if (accessToken) return handleCashReceived()
        }
      } catch (error) {
        error.response.data = decryptData(error.response?.data?.cipher)
        if (error.response.status === 401) {
          await getNewAccessToken()
          if (accessToken) return handleCashReceived()
        }
        setSuccess(false)
        toast.error(error?.response?.data?.message)
      } finally {
        setLoading(null)
      }
      setButtonClicked(false)
    }
  }

  useEffect(() => {
    if (
      remittanceData &&
      remittanceData?.riderReqeustStatus === 'cashReceivedBack'
    ) {
      setAssignAgain(false)
    }
  }, [remittanceData])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setIsSignoffClicked(true),
  })

  return (
    <>
      {open && (
        <Popup
          open={open}
          setOpen={setOpen}
          type={success ? 'Success' : 'Error'}
          detail={success ? 'Successful submission' : 'No Record Found'}
          move={true}
        />
      )}
      <Box>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12} sm={12}>
              <Paper sx={{ borderRadius: '12px', marginTop: '12px' }}>
                <Box
                  bgcolor={'#CCCCCC'}
                  sx={{ borderRadius: '12px 12px 0 0' }}
                  paddingY="10px"
                >
                  <Typography color="#074F93" variant="h4" marginLeft={'12px'}>
                    Rider Details
                  </Typography>
                </Box>
                <Box sx={{ padding: '25px' }}>
                  <Grid container className="ms-auto" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: '5px' }}
                    >
                      <InputField
                        key={'1'}
                        id="riderName"
                        name="riderName"
                        type="text"
                        placeholder=" Rider Name"
                        label=""
                        textLabel=" Rider Name"
                        value={formik?.values?.riderName || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        error={
                          formik?.touched?.riderName &&
                          Boolean(formik?.errors?.riderName)
                        }
                        helperText={
                          formik?.touched?.riderName &&
                          formik?.errors?.riderName
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <InputField
                        key={'1'}
                        id="riderContactNumber"
                        name="riderContactNumber"
                        type="text"
                        placeholder=" Rider Contact Number"
                        label=""
                        disabled={true}
                        textLabel=" Rider Contact Number"
                        value={formik?.values?.riderContactNumber || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik?.touched?.riderContactNumber &&
                          Boolean(formik?.errors?.riderContactNumber)
                        }
                        helperText={
                          formik?.touched?.riderContactNumber &&
                          formik?.errors?.riderContactNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputField
                        key={'1'}
                        id="riderCNIC"
                        name="riderCNIC"
                        type="text"
                        placeholder=" Rider CNIC"
                        label=""
                        disabled={true}
                        textLabel=" Rider CNIC"
                        value={formik?.values?.riderCNIC || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik?.touched?.riderCNIC &&
                          Boolean(formik?.errors?.riderCNIC)
                        }
                        helperText={
                          formik?.touched?.riderCNIC &&
                          formik?.errors?.riderCNIC
                        }
                      />
                    </Grid>
                    <Grid item md={6}></Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          paddingY: 2,
                          marginTop: '35px',
                          borderRadius: 2,
                          border: ' 1px solid #707070',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Stack spacing={3}>
                          <Box>
                            <h5 style={{ marginBottom: '8px' }}>
                              Profile photo
                            </h5>
                            <div
                              style={{
                                display: 'inline-flex',
                              }}
                            >
                              <div>
                                <img
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  src={
                                    data?.profileImage
                                      ? process.env.REACT_APP_IMAGE_URL +
                                        data?.profileImage
                                      : ImgIcon
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100pt',
                                  }}
                                  alt="img"
                                />
                              </div>
                            </div>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          paddingY: 2,
                          marginTop: '35px',
                          borderRadius: 2,
                          border: ' 1px solid #707070',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Stack spacing={3}>
                          <Box>
                            <h5 style={{ marginBottom: '8px' }}>Cnic Front</h5>
                            <div>
                              <img
                                color="secondary"
                                variant="contained"
                                component="span"
                                src={
                                  data?.cnicFront
                                    ? process.env.REACT_APP_IMAGE_URL +
                                      data?.cnicFront
                                    : cnincFront
                                }
                                style={{
                                  cursor: 'pointer',
                                  width: '100%',
                                  height: '100pt',
                                }}
                                alt="img"
                              />
                            </div>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          paddingY: 2,
                          marginTop: '35px',
                          borderRadius: 2,
                          border: ' 1px solid #707070',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Stack spacing={3}>
                          <Box>
                            <h5 style={{ marginBottom: '8px' }}>Cnic Back</h5>
                            <div>
                              <img
                                color="secondary"
                                variant="contained"
                                component="span"
                                src={
                                  data?.cnicBack
                                    ? process.env.REACT_APP_IMAGE_URL +
                                      data?.cnicBack
                                    : cnicBack
                                }
                                style={{
                                  cursor: 'pointer',
                                  width: '100%',
                                  height: '100pt',
                                }}
                                alt="img"
                              />
                            </div>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    align="center"
                    justify="center"
                    marginY={'30px'}
                  >
                    <Grid item xs={12}>
                      {data?.status &&
                      ((remittanceData?.requestStatus === 'forwarded' &&
                        !remittanceData?.riderReqeustStatus) ||
                        assignAgain) ? (
                        <InputButton
                          size={'small'}
                          loading={loadingButton === 'submit'}
                          sx={{
                            marginTop: '0',
                            padding: '0.5rem 2rem',
                            marginRight: '12px',
                          }}
                          disabled={
                            buttonClicked ||
                            Object?.keys(data ?? {})?.length === 0
                              ? true
                              : false
                          }
                          type="submit"
                          label={
                            assignAgain
                              ? 'Assign again'
                              : Object?.keys(data ?? {})?.length === 0
                              ? 'Rider not found'
                              : 'Assign'
                          }
                        />
                      ) : remittanceData?.riderReqeustStatus ===
                        'assignToRider' ? (
                        <>
                          <InputButton
                            size={'small'}
                            sx={{
                              marginTop: '0',
                              padding: '0.5rem 2rem',
                              marginRight: '12px',
                            }}
                            loading={loadingButton === 'handOverToRider'}
                            onClick={handleHandOvertoRider}
                            disabled={!isSignoffClicked || buttonClicked}
                            label="Hand over to rider"
                          />
                          <InputButton
                            size={'small'}
                            sx={{
                              marginTop: '0',
                              padding: '0.5rem 2rem',
                              marginRight: '12px',
                            }}
                            loading={loadingButton === 'revertedByMaker'}
                            onClick={handleRevertedByMaker}
                            disabled={buttonClicked}
                            label="Revert To Maker"
                          />
                        </>
                      ) : remittanceData?.riderReqeustStatus === 'BVSFailed' ||
                        remittanceData?.riderReqeustStatus ===
                          'beneficaryNotFound' ? (
                        <InputButton
                          size={'small'}
                          sx={{
                            marginTop: '0',
                            padding: '0.5rem 2rem',
                            marginRight: '12px',
                          }}
                          loading={loadingButton === 'cashReceived'}
                          disabled={buttonClicked}
                          onClick={handleCashReceived}
                          label="Cash received back"
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </form>
          <Box mt={3} display="flex" justifyContent="center">
            {remittanceData?.riderReqeustStatus === 'assignToRider' && (
              <InputButton
                size="small"
                sx={{
                  marginTop: '0',
                  padding: '0.5rem 2rem',
                  marginRight: '12px',
                  marginBottom: '12px',
                }}
                onClick={() => {
                  handlePrint()
                  setIsSignoffClicked(true)
                }}
                label="Signoff Form"
              />
            )}
          </Box>
          <div style={{ display: 'none' }}>
            <PrintableContainer
              ref={printRef}
              formik={formik}
              data={data}
              remittanceData={remittanceData}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}

export default Detail
