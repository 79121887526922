import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  popupModal: {
    '& .MuiBackdrop-root': {
      backgroundColor: theme.palette.colorsName.popupModalBackdrop,
    },
    '& .MuiDialog-paper': {
      borderRadius: 10,
      boxShadow: 'none',
      textAlign: 'center',
      minWidth: '400px',
      '& .MuiDialogTitle-root': {
        padding: '0 0 1rem 0',
        color: theme.palette.primary.main,
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: '1.5rem',
      },
      '& .MuiDialogContent-root': {
        padding: 0,
        '& .MuiDialogContentText-root': {
          padding: '0 0 1rem 0',
          color: theme.palette.primary.main,
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '1.5rem',
        },
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        marginBottom: '1.5rem',
        '& .MuiButton-root': {
          padding: '0.75rem 2.5rem',
        },
      },
    },
  },
  popupIcon: {
    '&.MuiAvatar-root': {
      width: '70px',
      height: '70px',
      margin: '2rem auto',
      //   [theme.breakpoints.down('md')]: {
      //     width: '35px',
      //     height: '35px',
      //   },
    },
  },
  popupSvgIcon: {
    '&.MuiSvgIcon-root': {
      width: '100px',
      height: '100px',
      margin: '2rem 0 0.5rem',
    },
  },
}))

export default useStyles
