import React from 'react'
import { Button } from '@mui/material'
import useStyles from '../../styles/InputButtonStyles'
import CircularProgress from '@mui/material/CircularProgress'
const InputButton = ({
  fullWidth,
  color,
  name,
  type,
  variant,
  disabled,
  disableElevation,
  disableFocusRipple,
  disableRipple,
  startIcon,
  endIcon,
  href,
  onClick,
  size,
  sx,
  label,
  loading,
}) => {
  const classes = useStyles()
  return (
    <Button
      fullWidth={fullWidth}
      color={color ?? 'primary'}
      name={name}
      type={type}
      variant={variant ?? 'contained'}
      disabled={disabled}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      startIcon={startIcon}
      endIcon={endIcon}
      href={href}
      onClick={onClick}
      size={size ?? 'medium'}
      sx={sx}
      className={classes.inputButton}
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {' '}
        {loading && (
          <CircularProgress
            color="inherit"
            style={{ width: '30px', height: '30px', marginRight: '20px' }}
          />
        )}
        {label}
      </span>
    </Button>
  )
}

export default InputButton
