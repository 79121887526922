/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import GlobalStyles from './GlobalStyles'
import breakpoints from './breakpoints'
import typography from './typography'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const Theme = ({ children }) => {
  const themeOptions = useMemo(() => {
    return {
      palette: {
        primary: {
          main: '#053E74',
          contrastText: '#FFFFFF',
        },
        hover: {
          main: '#D6E6F6',
        },
        colorsName: {
          darkSilver: '#707070',
          SpanishGray: '#999999',
          popupModalBackdrop: 'rgba(153, 153, 153, 0.5)',
        },
        text: {
          primary: '#000000',
          secondary: '#050505',
          light: '#FFFFFF',
          disabled: '#637381',
        },
        secondary: {
          main: '#CCCCCC',
          contrastText: '#000000',
        },
      },
      // components: {
      //   MuiAppBar: {
      //     styleOverrides: {
      //       root: {
      //         // backgroundColor: 'green',
      //       },
      //     },
      //   },
      // },
      breakpoints,
      typography,
      drawerWidth: 240,
    }
  }, [])

  const theme = createTheme(themeOptions)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default Theme
