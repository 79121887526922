import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom'
import InputField from '../components/common/InputField'
import InputButton from '../components/common/InputButton'
import loginLogo from '../assets/bank-logo-white.svg'
import loginIcon from '../assets/login-icon.svg'
import useStyles from '../styles/LoginScreenStyles'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  loginSuccess,
  logout,
  storeUserData,
} from '../redux/action/loginActions'
import { setAccessToken } from '../redux/action/tokenAction'
import { decryptData, encryptData } from '../services/EncryptTemplate'
const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const UpdatePassword = props => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const location = useLocation()

  const token = location.state.token
  // const { token } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      res.data = decryptData(res?.data?.cipher)
      if (res.status === 200) {
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }

  const makePasswordResetRequest = async obj => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Url}/user/updatePassword`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        toast.success('Password responseet Successfully')
        navigate('/login')
      }
      if (response.status === 401) {
        await getNewAccessToken()
        await makePasswordResetRequest(obj)
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)

      if (error.response.status === 401) {
        await getNewAccessToken()
        await makePasswordResetRequest(obj)
      } else {
        toast.error(error?.response?.data?.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setLoading(true)
      let obj = {
        userId: user.id,
        password: values.password,
        // confirmPassword: values.confirmPassword,
      }
      obj = encryptData(obj)

      await makePasswordResetRequest(obj)
    },
  })
  return (
    <>
      <Box className={classes.loginScreen}>
        <Avatar
          alt="JS Bank Logo"
          className={classes.loginBankLogo}
          src={loginLogo}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={11} sm={8} md={8} lg={6} xl={5}>
            <Paper className={classes.loginInnerScreen}>
              <Avatar
                alt="Login Screen Icon"
                className={classes.loginIcon}
                src={loginIcon}
              />
              <Typography
                className={classes.mainHeading}
                gutterBottom
                variant="body1"
                marginBottom={'2rem'}
              ></Typography>
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  fullWidth
                  size={'small'}
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  inputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={event => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />

                <InputField
                  fullWidth
                  size={'small'}
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  inputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={event => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
                <Box textAlign={'center'}>
                  <InputButton
                    size={'medium'}
                    color={'primary'}
                    variant={'contained'}
                    type="submit"
                    loading={loading}
                    label={'confirm Password'}
                    sx={{ marginTop: '0', padding: '0.5rem 1.5rem' }}
                  />
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default UpdatePassword
