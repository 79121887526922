import * as React from 'react'
import Box from '@mui/material/Box'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import useStyles from '../../styles/DashboardLayoutStyles'

const Dashboard = ({ children, pageName }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar open={open} setOpen={setOpen} pageName={pageName} />
      <Sidebar open={open} />
      <main
        className={`${
          open ? classes.dashboardMainOpen : classes.dashboardMain
        }`}
        style={{
          maxWidth:'100%',
          visibility: 'visible',
          height: 'auto',
          width: 'auto'
        }}
      >
        <Box className={classes.drawerHeader} />
        {children}
      </main>
    </Box>
  )
}

export default Dashboard
