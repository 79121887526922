// store.js
import { createStore, combineReducers } from 'redux';
import loginReducer from './redux/reducer/loginReducer';
import tokenReducer from './redux/reducer/tokenReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  token:tokenReducer,
});

const store = createStore(rootReducer);

export default store;
