import React from 'react'
import { Box, Typography, Grid, Paper } from '@mui/material'
import ImgIcon from '../../assets/profile_photo.jpg'
import cnincFront from '../../assets/cnic front.png'
import cnicBack from '../../assets/cnic back.png'
import moment from 'moment'
import InputField from '../../components/common/InputField'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  pageBreak: {
    '@media print': {
      pageBreakBefore: 'always',
    },
  },
  container: {
    padding: '25px',
  },
  title: {
    color: '#074F93',
    marginBottom: '5px',
  },
  gridItem: {
    marginTop: '5px',
  },
  signatureBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    justifyContent: 'space-between',
    padding: '30px',
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    width: 'calc(100% - 50px)',
    gap: '10rem',
    padding: '10px',
  },
  signatureContainersecond: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:"center",
    margin:'0 auto',
    width:'70%',
    padding: '10px',
  },
  signatureField: {
    paddingY: 2,
    marginTop: '35px',
    borderRadius: 2,
    border: '1px dashed #707070',
    display: 'flex',
    justifyContent: 'center',
    height: '50px',
    alignItems: 'center',
    position: 'relative',
  },
  signatureLabel: {
    position: 'absolute',
    top: '-20px',
    transform: 'translateX(-25%)',
    left: '20%',
  },
  profileImage: {
    cursor: 'pointer',
    width: '100%',
    height: '100pt',
  },
  formPaper: {
    borderRadius: '12px',
    margin: '15px',
  },
  formHeader: {
    borderRadius: '12px 12px 0 0',
    paddingY: '10px',
    backgroundColor: '#CCCCCC',
  },
  formHeaderTitle: {
    padding: '12px',
    color: '#074F93',
    marginLeft: '12px',
  },
  formBody: {
    padding: '30px 30px 0px 30px',
  },
}))

const PrintableComponent = React.forwardRef((props, ref) => {
  const { formik, data, remittanceData } = props
  const deliveryDate = moment(remittanceData?.deliveryDate?.$d).format(
    'MMMM Do YYYY, h:mm:ss a'
  )
  const classes = useStyles()
  return (
    <Box ref={ref}>
      <form onSubmit={formik.handleSubmit}>
        <Paper className={classes.formPaper}>
          <Box className={classes.formHeader}>
            <Typography variant="h4" className={classes.formHeaderTitle}>
              Remittance Details
            </Typography>
          </Box>
          <Box className={classes.formBody}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <InputField
                  textLabel="Transaction Reference"
                  value={remittanceData?.transactionReference || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Beneficiary Contact"
                  value={remittanceData?.beneficiaryContact || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Remitter Name"
                  value={remittanceData?.remitterName || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Remitter Address"
                  value={remittanceData?.remitterAddress || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Beneficiary Name"
                  value={remittanceData?.beneficiaryName || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Beneficiary CNIC"
                  value={remittanceData?.beneficiaryCNIC || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Amount"
                  value={remittanceData?.amount || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Dollar equivalent"
                  value={remittanceData?.dollarEquivalent}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Beneficiary Branch Add 1 (City region)"
                  value={remittanceData?.beneficiaryBranchAdd1 || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Beneficiary Branch Add 2"
                  value={remittanceData?.beneficiaryBranchAdd2 || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Home Address"
                  value={remittanceData?.homeAddress || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Ultimate Remitter Country"
                  value={remittanceData?.ultimateRemitterCountry || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Delivery Date Time"
                  value={deliveryDate}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  textLabel="Assigned Branch"
                  value={remittanceData?.branchName}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} className={classes.pageBreak}>
          <Paper className={classes.formPaper}>
            <Box className={classes.formHeader}>
              <Typography variant="h4" className={classes.formHeaderTitle}>
                Rider Details
              </Typography>
            </Box>
            <Box className={classes.formBody}>
              <Grid container spacing={2}>
                <Grid item xs={6} className={classes.gridItem}>
                  <InputField
                    textLabel="Rider Name"
                    value={formik?.values?.riderName || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    textLabel="Rider Contact Number"
                    value={formik?.values?.riderContactNumber || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    textLabel="Rider CNIC"
                    value={formik?.values?.riderCNIC || ''}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <div className={classes.signatureBox}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="h5" marginBottom="8px">
                        Profile photo
                      </Typography>
                      <Box display="inline-flex">
                        <img
                          src={
                            data?.profileImage
                              ? process.env.REACT_APP_IMAGE_URL +
                                data?.profileImage
                              : ImgIcon
                          }
                          className={classes.profileImage}
                          alt="Profile"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="h5" marginBottom="8px">
                        CNIC Front
                      </Typography>
                      <Box display="inline-flex">
                        <img
                          src={
                            data?.cnicFront
                              ? process.env.REACT_APP_IMAGE_URL +
                                data?.cnicFront
                              : cnincFront
                          }
                          className={classes.profileImage}
                          alt="CNIC Front"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="h5" marginBottom="8px">
                        CNIC Back
                      </Typography>
                      <Box display="inline-flex">
                        <img
                          src={
                            data?.cnicBack
                              ? process.env.REACT_APP_IMAGE_URL + data?.cnicBack
                              : cnicBack
                          }
                          className={classes.profileImage}
                          alt="CNIC Back"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </div>
                <Grid container className="flex justify-content-center">
                  <Box className={classes.signatureContainer}>
                    <Grid item xs={6}>
                      <Box className={classes.signatureField}>
                        <Typography
                          variant="p"
                          className={classes.signatureLabel}
                        >
                          Rider's Signature
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className={classes.signatureField}>
                        <Typography
                          variant="p"
                          className={classes.signatureLabel}
                        >
                          Teller's Signature
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container className="flex justify-content-center">
                  <Box className={classes.signatureContainersecond}>
                    <Grid item xs={6}>
                      <Box className={classes.signatureField}>
                        <Typography
                          variant="p"
                          className={classes.signatureLabel}
                        >
                          Reciever's Signature
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </form>
    </Box>
  )
})

export default PrintableComponent
