import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const Pagination = ({ start, end, total, handleNext, handlePre }) => {

  const handlePreviousClick = () => {
    if (start > 1) {
      handlePre();
    }
  };

  const handleNextClick = () => {
    if (end < total) {
      handleNext();
    }
  };

  return (
    <Box display={'flex'} justifyContent={'flex-end'} marginTop={'15px'}>
      <Typography variant="body1" fontSize={'14px'}>
        Now showing page {start}
        {/* to {end}  */}
        <span style={{ margin: '0 2px 0 4px' }}>| </span>total record {total}
      </Typography>{' '}
      <Box
        variant="body1"
        marginLeft={'12px'}
        fontSize={'14px'}
        color={'#2360EF'}
        display={'flex'}
      >
        <Typography
          fontSize={'14px'}
          color={'#2360EF'}
          marginRight={'2px'}
          onClick={handlePreviousClick}
          style={{
            cursor: start === 1 ? 'default' : 'pointer',
            color: start === 1 ? 'gray' : 'blue',
          }}
        >
          {' '}
          Pre
        </Typography>
        |{' '}
        <Typography
          fontSize={'14px'}
          color={'#2360EF'}
          marginLeft={'2px'}
          onClick={handleNextClick}
          style={{
            cursor: end < total? 'pointer' : 'default',
            color: end < total ? 'blue' : 'gray',
          }}
        >
          {' '}
          Next
        </Typography>
      </Box>
    </Box>
  )
}

export default Pagination
