import AppRouter from './routers'
import ThemeConfig from './theme'
import { useLocation } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
  const params = useLocation()
  const paramName = params.pathname.split('/')[1].toString()

  const newName = paramName.replace('_', ' ')

  return (
    <>
      <ThemeConfig>
        {/* <DashboardLayout pageName={newName}> */}
        <ToastContainer />
        <AppRouter />
        {/* </DashboardLayout> */}
      </ThemeConfig>
    </>
  )
}
