// src/pages/Inbox.jsx
import React, { useState, useEffect } from 'react'
import io from 'socket.io-client'
import DashboardLayout from '../../layouts/Dashboard'
import UserTable from '../../components/common/Table'

import { useFetcher, useNavigate } from 'react-router-dom'
// import Popup from '../components/common/Popup'
import { Box } from '@mui/material'
import Pagination from '../../components/common/Pagination'
// import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/action/loginActions'
import { setAccessToken } from '../../redux/action/tokenAction'
import { decryptData, encryptData } from '../../services/EncryptTemplate'

const Inbox = () => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const role = sessionStorage.getItem('role')

  const [firstPage, setFirstPage] = useState(1)
  const [lastPage, setLastPage] = useState(25)
  const [loading, setLoading] = useState(false)
  const [data1, setData1] = useState()
  const [count, setCount] = useState(0)
  const columns = [
    {
      id: 'transactionReference',
      name: 'Transaction Reference',
      selector: row => row.requestId,
      sortable: true,
      minWidth: 50,
      align: 'left',
    },
    // role !== 'maker' && {
    //   id: 'requestId',
    //   name: 'Request ID',
    //   selector: row => row.requestId,
    //   sortable: true,
    //   minWidth: 50,
    //   align: 'left',
    // },
    // role == 'checker' && {
    //   id: 'firstName',
    //   name: 'Maker',
    //   selector: row => row.firstName,
    //   sortable: true,
    //   minWidth: 50,
    //   align: 'left',
    // },
    {
      id: 'attempt',
      name: 'Attempt',
      selector: row => row.attempt,
      sortable: true,
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'remitterName',
      name: 'Remitter Name',
      selector: row => row.remitterName,
      sortable: true,
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'beneficiaryName',
      name: 'Beneficiary Name',
      selector: row => row.beneficiaryName,
      sortable: true,
      minWidth: 120,
      align: 'left',
    },

    {
      id: 'reqeustStatus',
      name: 'Request Status',
      selector: row => row.status,
      sortable: true,
      align: 'left',
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      align: 'left',
    },
    {
      id: 'action',
      name: 'Action ',
      selector: row => row.action,
      sortable: true,
      align: 'left',
    },
  ]
  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      res.data = decryptData(res?.data?.cipher)
      if (res.status === 200) {
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }
  const attemptIndex = columns.findIndex(column => column.id === 'attempt')
  if (role === 'checker') {
    columns.splice(attemptIndex + 1, 0, {
      id: 'firstName',
      name: 'Maker',
      selector: row => row.firstName,
      sortable: true,
      minWidth: 50,
      align: 'left',
    })
  }
  if (role !== 'maker') {
    columns.splice(attemptIndex + 1, 0, {
      id: 'requestId',
      name: 'Request ID',
      selector: row => row.requestId,
      sortable: true,
      align: 'left',
    })
  }

  const getRemittance = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/remittance`,
        {
          params: {
            pageNumber: firstPage,
            numberOfRecordsPerPage: 25,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        console.log(response, 'res status')
        await getNewAccessToken()
        return getRemittance()
      }
      const data = response.data
      setCount(data?.data?.count)
      setData1(data?.data?.requests)
      return data?.data?.requests
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getRemittance()
      }
      console.error('Error fetching remittance data:', error)
    }
  }
  useEffect(() => {
    getRemittance()
  }, [firstPage, lastPage])

  const handlePre = () => {
    if (firstPage > 1) {
      setFirstPage(firstPage - 1)
      setLastPage(lastPage - 25)
    }
  }
  const handleNext = () => {
    if (lastPage < count) {
      setFirstPage(firstPage + 1)
      setLastPage(lastPage + 25)
    }
  }
  const handleShowRemittance = id => {
    navigate(`/inbox-detail?id=${id}`)
  }

  const handleUploadTransaction = async () => {
    setLoading(true)
    const id = sessionStorage.getItem('userId')

    let formPayload = {
      createdBy: parseInt(id),
    }
    formPayload = encryptData(formPayload)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Url}/remittance`,
        formPayload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        toast.success(response?.data?.message)

        await getRemittance()
        // window.location.reload()
      }
      if (response.status === 401) {
        console.log(response, 'resp status handle Upload')
        await getNewAccessToken()
        return handleUploadTransaction()
      }
    } catch (error) {
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleUploadTransaction()
      }
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  // const { mutateAsync: uploadTransaction } = useMutation({
  //   mutationFn: handleUploadTransaction,
  // })

  const handActionView = row => {}

  useEffect(() => {
    const socket = io(
      `${process.env.REACT_APP_Socket_app_url}/?userId=${6}&branchId=${1}`
    )
    socket.on('connect', () => {
      getRemittance()
    })
    socket.on('riderCashPickedUpAcknowledge', data => {
      // Handle the incoming data from the socket
    })

    return () => {
      socket.disconnect()
    }
  }, [])
  useEffect(() => {
    if (!data1) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [data1])

  return (
    <DashboardLayout>
      <Box marginTop={'50px'}>
        <>
          <UserTable
            tableHeading={columns}
            tableData={data1}
            isAdvanceSearch={false}
            isPagination={true}
            tableButton={role === 'maker' ? true : false}
            sortServer={false}
            loading={loading}
            buttonText={'Upload Transactions'}
            title={'Pending Request'}
            buttonOnClick={async () => {
              try {
                await handleUploadTransaction()
              } catch (e) {
                console.error(e)
              }
            }}
            handleShowRemittance={handleShowRemittance}
            handActionView={handActionView}
          />
          <Pagination
            start={firstPage}
            end={lastPage}
            total={count}
            handleNext={handleNext}
            handlePre={handlePre}
          />
        </>
        {/* )} */}

        {/* <InputButton label="Alert" onClick={() => setOpen(true)} />
      {open && (
        <Popup
          open={open}
          setOpen={setOpen}
          type="Error"
          detail="No Record Found"
        />
      )} */}
      </Box>
    </DashboardLayout>
  )
}

export default Inbox
