import CryptoJS from "crypto-js";
 
// Set encryption key and IV. In a real app, don't expose these values directly in your code.
// They should come from a secure environment.
const cryptkey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTKEY || "2ee5005dad9dce76");
const cryptiv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTIV || "2ee5005dad9dce76");
 console.log(cryptkey)
// Function to encrypt data
export function encryptData(obj) {
  try {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(obj);
 
    // Encrypt the JSON string using CryptoJS
    const encrypted = CryptoJS.AES.encrypt(jsonString, cryptkey, {
      iv: cryptiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
 
    // Return the cipher as a base64-encoded string
    const cipher = encrypted.toString();
    return { cipher };
  } catch (error) {
    console.error("Encryption error: ", error);
    return null;
  }
}
 
// Function to decrypt a base64-encoded cipher to an object
export function decryptData(cipherText) {
  try {
    // Decrypt the cipher using CryptoJS
    const decrypted = CryptoJS.AES.decrypt(cipherText, cryptkey, {
      iv: cryptiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
 
    // Convert the decrypted WordArray to a JSON string and then to an object
    const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
    if (jsonString) {
      return JSON.parse(jsonString);
    }
    return null;
  } catch (error) {
    console.error("Decryption error: ", error);
    return null;
  }
}