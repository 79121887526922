import * as React from 'react'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AlertIcon from '../../assets/alert-icon.svg'
import useStyles from '../../styles/PopupModalStyles'
import InputButton from './InputButton'
import { useNavigate } from 'react-router-dom'
const Popup = ({ open, setOpen, size, type, detail, reqNum, move }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const handleClose = () => {
    setOpen(false)
    {
      move && navigate('/inbox')
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        maxWidth={size ?? 'sm'}
        onClose={() => setOpen(false)}
        aria-labelledby="popup-alerts"
        aria-describedby="popup-for-different-type-of-alerts"
        className={classes.popupModal}
      >
        <Box>
          {type === 'Alert' || type === 'Error' ? (
            <Avatar
              alt="Alert Icon"
              src={AlertIcon}
              className={classes.popupIcon}
            />
          ) : (
            // <Avatar
            //   alt="Alert Icon"
            //   src={AlertIcon}
            //   className={classes.popupIcon}
            // />
            <CheckCircleOutlineIcon
              color="primary"
              className={classes.popupSvgIcon}
            />
          )}
        </Box>
        <DialogTitle id="Alert Type">{type}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert description">{detail}</DialogContentText>
          {reqNum && (
            <DialogContentText>Request Number is {reqNum}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <InputButton
            label="Close"
            size={'large'}
            onClick={() => handleClose()}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Popup
