import React from 'react'
import { makeStyles } from '@mui/styles'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EditIcon from '@mui/icons-material/Edit'
import { v4 as uuidv4 } from 'uuid'
const useStyles = makeStyles(theme => ({
  formControl: {
    '& .MuiInputBase-root': {
      // border: `1px solid ${theme.palette.colorsName.darkSilver}`,
      borderRadius: '100px',
      // minWidth: '18rem',
      fontWeight: 100,
      justifyContent: 'center',
      fontSize: '12px',
    },
    '& .css-gp1zyi-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-error .MuiOutlinedInput-notchedOutline ':
      {
        borderColor: '#C5C5C5',
      },
    '& .css-pmdi93.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C5C5C5',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0px',
    },
  },

  select: {
    width: 'auto',
    fontSize: '12px',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    position: 'relative',
    color: '#6EC177',
    fontSize: '14px',
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#074F93',
    },
    '& li.Mui-selected:hover': {
      background: '#074F93',
    },
  },
}))

const DropDown = ({
  value,
  onChange,
  items,
  label,
  name,
  textLabel,
  icon,
  width,
  optionName,
  error,
  helperText,
  optionValue,
  onBlur,
  id,
  inputProps,
  disabled,
  textColor,
  edit,
  editError,
}) => {
  const classes = useStyles()

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    // getContentAnchorEl: null,
  }

  return (
    <FormControl className={classes.formControl} sx={{ width: '100%' }}>
      <InputLabel id="demo-select-small-label">{label}</InputLabel>

      {textLabel && (
        <Box display={'flex'} alignItems={'center'}>
          <label
            style={{
              fontSize: '12px',
              marginLeft: '10px',
              marginBottom: '4px',
              color: '#074F93',
            }}
          >
            {textLabel}
          </label>
          <Box ml="5px">
            {' '}
            {icon === 'editIcon' && <EditIcon style={{ width: '15px' }} />}
          </Box>
        </Box>
      )}
      <Select
        value={value}
        name={name}
        id={id}
        labelId="demo-select-small-label"
        label={label}
        onChange={onChange}
        // disableUnderline
        IconComponent={ArrowDropDownIcon}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
        disabled={disabled ? true : false}
        onBlur={onBlur}
        error={error}
        InputProps={inputProps}
        helperText={helperText}
        editError={editError}
        style={{ color: textColor }}
      >
        {items?.map(item => (
          <MenuItem key={uuidv4()} value={item?.[optionValue]}>
            {item?.[optionName].charAt(0).toUpperCase() +
              item?.[optionName]?.slice(1)}
          </MenuItem>
        ))}
      </Select>
      {!edit && (
        <p
          style={{
            color: '#d32f2f',
            lineHeight: '1.5',
            fontWeight: '400',
            fontSize: '0.75rem',
            textAlign: 'left',
            marginTop: '3px',
            marginRight: '14px',
            marginBottom: '0',
            marginLeft: '14px',
          }}
        >
          {helperText}
        </p>
      )}
      {edit && (
        <p
          style={{
            color: '#d32f2f',
            lineHeight: '1.5',
            fontWeight: '400',
            fontSize: '0.75rem',
            textAlign: 'left',
            marginTop: '3px',
            marginRight: '14px',
            marginBottom: '0',
            marginLeft: '14px',
          }}
        >
          {editError}
        </p>
      )}
    </FormControl>
  )
}

export default DropDown
