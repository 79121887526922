import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Table, Box, Typography, Paper } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import InputButton from './InputButton'
import InputField from './InputField'
import EyeIcon from '../../assets/eye-icon.svg'
import { v4 as uuidv4 } from 'uuid'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useLocation } from 'react-router-dom'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useSelector } from 'react-redux'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primaryText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border

  border: 0,
}))
// const isNewLogin =  sessionStorage.getItem('isNewLogin')
// console.log({ isNewLogin })

// if (isNewLogin == undefined || isNewLogin == 1) {
//    sessionStorage.setItem('isNewLogin', "0")
//   window.location.reload()
// }
const TableComp = ({
  tableHeading,
  tableData,
  handleShowRemittance,
  title,
  tableButton,
  buttonText,
  buttonOnClick,
  loading,
  handActionView,
  handActionEdit,
  isSearch,
  setSearchText,
  value,
}) => {
  const { user } = useSelector(state => state.login)
  const location = useLocation()
  const pathname = location.pathname
  const isInInboxPage = pathname === '/inbox'
  const onSearch = e => {
    setSearchText(e.target.value)
  }
  const getRowStatus = val => {
    const role = user?.role?.key
    if (!val) {
      if (role === 'maker') {
        return 'Pending'
      }
      if (role === 'teller') {
        return 'Forward'
      }
    }
    switch (val) {
      case 'created':
        return 'Created'
      case 'revert':
        return 'Reverted'
      case 'updated':
        return 'Updated'
      case 'forwarded':
        return 'Forwarded'
      case 'assignToRider':
        return 'Assign to rider'
      case 'handedOverToRider':
        return 'Handed over to rider'
      case 'riderCashPickedUp':
        return 'Rider picked up cash'
      case 'BVSPending':
        return 'BVS pending'
      case 'BVSSuccess':
        return 'BVS success'
      case 'BVSFailed':
        return 'BVS failed'
      case 'delivered':
        return 'Delivered'
      case 'beneficaryNotFound':
        return 'Beneficiary not found'
      case 'cashReceivedBack':
        return 'Cash received back'
      case 'revertedByTeller':
        return 'Reverted by teller'
      default:
        return val
    }
  }
  useEffect(() => {
    getRowStatus()
  }, [user])

  const getStatus = statusId => {
    const role = user?.role?.key
    switch (role) {
      case 'maker':
        switch (statusId) {
          case null:
            return 'Pending'
          case 2:
            return 'Reverted by checker'
          case 14:
            return 'Reverted by teller'
          case 1:
          case 3:
            return 'Pending at checker'
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:

          case 12:
          case 13:
            return 'Pending at teller'
          case 11:
            return 'Delivered'
          default:
            return 'Unknown Status'
        }

      case 'checker':
        switch (statusId) {
          case 1:
            return 'Created'
          case 3:
            return 'Updated'
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 12:
          case 13:
            return 'Pending at teller'
          case 11:
            return 'Delivered'
          default:
            return 'Unknown Status'
        }

      case 'teller':
        switch (statusId) {
          case 4:
            return 'Forwarded'
          case 5:
            return 'Assign to Rider'
          case 6:
            return 'Handed over to rider'
          case 7:
            return 'Rider cash picked up'
          case 8:
            return 'BVS Pending'
          case 9:
            return 'BVS Success'
          case 10:
            return 'BVS Failed'
          case 11:
            return 'Delivered'
          case 12:
            return 'Beneficiary Not Found'
          case 13:
            return 'Cash Recieved Back'
          default:
            return 'Unknown Status'
        }

      case 'superAdmin':
        switch (statusId) {
          case null:
          case 2:
          case 14:
            return 'Pending at maker'
          case 1:
          case 3:
            return 'Pending at checker'
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 12:
          case 13:
            return 'Pending at teller'
          case 11:
            return 'Delivered'
          default:
            return 'Unknown'
        }
      default:
        return 'Unknown'
    }
  }

  useEffect(() => {
    getStatus()
  }, [user])
  function capitalizeFirstLetter(str) {
    if (str?.length === 0) {
      return str
    } else {
      return str?.charAt(0).toUpperCase() + str?.slice(1)
    }
  }
  return (
    <Box>
      {' '}
      <Box display={'flex'} justifyContent={'space-between'}>
        {title && (
          <Typography variant="h4" className="userTableTitle mb-3">
            {title}
          </Typography>
        )}
        <Box display={'flex'} justifyContent={'space-around'}>
          <Box>
            {tableButton && (
              <InputButton
                loading={loading}
                size={'medium'}
                sx={{
                  marginTop: '0',
                  padding: '1rem 1.5rem',
                  marginRight: '12px',
                  marginBottom: '12px',
                }}
                text={buttonText}
                onClick={buttonOnClick}
                label={buttonText}
              />
            )}
          </Box>

          {isSearch ? (
            <div className="ms-auto">
              <InputField
                key={'1'}
                id="search"
                type="search"
                placeholder="Search"
                value={value}
                onChange={onSearch}
              />
            </div>
          ) : null}
        </Box>
      </Box>
      {tableData?.length == 0 ? (
        <TableContainer
          component={Paper}
          sx={{ maxWidth: '80vw', minHeight: 470, maxHeight: 470 }}
        >
          <Table sx={{ minWidth: 500 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeading?.map(heading => (
                  <TableCell
                    key={uuidv4()}
                    align={heading.align}
                    style={{ minWidth: heading.minWidth }}
                  >
                    {heading.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={tableHeading.length} align="center">
                  No records found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : !tableData ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ maxWidth: '80vw', minHeight: 470, maxHeight: 470 }}
        >
          <Table sx={{ minWidth: 500 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeading?.map(heading => (
                  <TableCell
                    key={uuidv4()}
                    align={heading.align}
                    style={{ minWidth: heading.minWidth }}
                  >
                    {heading.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map(row => (
                <StyledTableRow
                  style={{
                    backgroundColor:
                      user.role.key == 'maker' &&
                        row?.fundTransferred !== 1 &&
                        isInInboxPage
                        ? 'red'
                        : 'white',
                  }}
                  key={uuidv4()}
                >
                  {tableHeading?.map(heading =>
                    heading.id === 'requestId' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.requestId}
                      </StyledTableCell>
                    ) : heading.id === 'transactionReference' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.transactionReference}
                      </StyledTableCell>
                    ) : heading.id === 'remitterName' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.remitterName}
                      </StyledTableCell>
                    ) : heading.id === 'beneficiaryName' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.beneficiaryName}
                      </StyledTableCell>
                    ) : heading.id === 'remitterPassportNumber' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.remitterPassportNumber}
                      </StyledTableCell>
                    ) : heading.id === 'status' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.status}
                      </StyledTableCell>
                    ) : heading.id === 'reqeustStatus' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {getRowStatus(row.requestStatus)}
                      </StyledTableCell>
                    ) : heading.id === 'statusId' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {getStatus(row.statusId)}
                      </StyledTableCell>
                    ) : heading.id === 'user-status' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.status ? 'active' : 'inactive'}
                      </StyledTableCell>
                    ) : heading.id === 'amount' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.amount}
                      </StyledTableCell>
                    ) : heading.id === 'action' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ cursor: 'pointer' }}
                        key={uuidv4()}
                      >
                        <img
                          src={EyeIcon}
                          alt="icon"
                          onClick={() => handleShowRemittance(row.remittanceId)}
                          style={{ width: '25px' }}
                        />
                      </StyledTableCell>
                    ) : heading.id === 'firstName' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {user?.role?.key === 'checker' ?
                          (capitalizeFirstLetter(row.firstName) + ' ' + capitalizeFirstLetter(row.lastName))
                          : (row.firstName)}

                      </StyledTableCell>
                    ) : heading.id === 'attempt' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.attempt}
                      </StyledTableCell>
                    ) : heading.id === 'lastName' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.lastName}
                      </StyledTableCell>
                    ) : heading.id === 'email' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row.email}
                      </StyledTableCell>
                    ) : heading.id === 'role' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row?.role?.key}
                      </StyledTableCell>
                    ) : heading.id === 'branch' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row?.branch?.branchName}
                      </StyledTableCell>
                    ) : heading.id === 'city' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row?.cityId?.name}
                      </StyledTableCell>
                    ) : heading.id === 'employeeId' ? (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      >
                        {row?.employeeId}
                      </StyledTableCell>
                    ) : heading.id === 'userAction' ? (
                      <Box marginY="10px">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <Button
                                variant="outlined"
                                {...bindTrigger(popupState)}
                              >
                                Action
                              </Button>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => handActionView(row?.id)}
                                >
                                  View
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handActionEdit(row?.id)}
                                >
                                  Edit
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Box>
                    ) : (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={uuidv4()}
                      ></StyledTableCell>
                    )
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default TableComp
