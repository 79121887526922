import React, { useRef, useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  createFilterOptions,
} from '@mui/material'
import { useFormik } from 'formik'
import InputButton from '../../components/common/InputButton'
import InputField from '../../components/common/InputField'
import * as yup from 'yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import InputSelect from '../../components/common/InputSelectFeild'
import Profile from '../../assets/profile.png'
import cnincFront from '../../assets/cnic front.png'
import cnicBack from '../../assets/cnic back.png'

import DashboardLayout from '../../layouts/Dashboard'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Autocomplete from '../../components/common/Autocomplete'
import { useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/action/loginActions'
import { setAccessToken } from '../../redux/action/tokenAction'
import { decryptData, encryptData } from '../../services/EncryptTemplate'

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/

const useStyles = makeStyles(theme => ({
  editCircle: {
    position: 'relative',
    bottom: '40px',
    background: 'white',
    borderRadius: '100%',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    cursor: 'pointer',
  },
}))

const AddUser = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const validationSchema = yup.object({
    employeeId: yup.number('Should be a number').when('role', {
      is: role => role !== '5',
      then: () =>
        yup
          .number()
          .required('Employee Id is required')
          .typeError('Value must be a number')
          .max(9999999, 'Employee ID should be at most 7 digits'),
      otherwise: () =>
        yup
          .number()
          .typeError('Value must be a number')
          .notRequired()
          .max(9999999, 'Employee ID should be at most 7 digits'),
    }),
    firstName: yup
      .string('')
      .required('First Name is required')
      .max(30, 'Must be 30 characters or less')
      .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed'),

    city: yup.string().when(['role', 'status'], {
      is: (role, status) => status == '1' && role === '5',
      then: () =>
        yup
          .string()
          .required('City is required')
          .notOneOf(['default'], 'City is not selected'),
      otherwise: () => yup.string(),
    }),
    lastName: yup
      .string('')
      .required('Last Name is required')
      .max(30, 'Must be 30 characters or less')
      .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed'),

    email: yup
      .string('')
      .email('Invalid email address')
      .required('Email Address is required')
      .matches(
        /^[A-Za-z0-9.@]+$/,
        'Only alphabets, numerics, period (.), and @ are allowed'
      )
      .max(40, 'Maximum length 40 allowed'),
    role: yup
      .string('')
      .required('Role is required')
      .notOneOf(['default'], 'Role is not selected'),

    cnic: yup.string().when('role', {
      // || role === '4'
      is: role => role === '5',
      then: () => yup.string().required('CNIC is required'),
      otherwise: () => yup.mixed().nullable(),
    }),

    status: yup.string(),
    //Number starts with 92 and should be 12 digits long
    phone: yup
      .string('')
      .matches(
        /^92[0-9]{10}$/,
        'Invalid Contact, Phone number must start with 92 and have 12 digits'
      )
      .required('Contact is required'),
    password:
      !id &&
      yup.string().when('role', {
        is: role => role === '5',
        then: () =>
          yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .max(15, 'Maximum length 15 allowed')
            .required('Password is required')
            .matches(
              passwordRegex,
              'Password should have at least one uppercase letter, one lowercase letter, one digit, and one special character.'
            ),
        otherwise: () => yup.string().nullable(),
      }),
    // password:
    //   !id &&
    //   yup
    //     .string()
    //     .min(8, 'Password should be of minimum 8 characters length')
    //     .max(15, 'Maximum length 15 allowed')
    //     .required('Password is required')
    //     .matches(
    //       passwordRegex,
    //       'Password should have at least one uppercase letter, one lowercase letter, one digit, and one special character.'
    //     ),
    branch: yup.string().when(['role', 'status'], {
      is: (role, status) => status == '1' && (role === '5' || role === '4'),
      then: () =>
        yup
          .string()
          .required('Branch is required')
          .notOneOf(['default'], 'Branch is not selected'),
      otherwise: () => yup.mixed().nullable().notRequired(),
    }),
  })
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  let {
    accessToken,
    refreshToken,
    user: userId,
  } = useSelector(state => state.login)
  const mdUp = useMediaQuery(theme.breakpoints.up('lg'))
  const [loading, setLoading] = useState(false)

  const inputElementProfile = useRef(null)
  const inputElementFront = useRef(null)
  const inputElementBack = useRef(null)
  const [showPassword, setShowPassword] = useState(false)

  const [dataFrontCnic, setDataFrontCnic] = useState(null)
  const [dataBackCnic, setDataBackCnic] = useState(null)
  const [dataProfile, setDataProfile] = useState(null)
  const [branchError, setBranchError] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(null)
  const [loader, setLoader] = useState(false)
  const [user, setUser] = useState()
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const [cities, setCities] = useState()
  const [branchCode, setBranchCode] = useState()
  const [buttonClicked, setButtonClicked] = useState(false)
  const [LDAPData, setLDAPData] = useState([])
  const [check, setCheck] = useState(false)

  const getNewAccessToken = async () => {
    if (!refreshToken) {
      console.error('Refresh token not found')
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: userId?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )

      if (res.status === 200) {
        res.data = decryptData(res?.data?.cipher)
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }

  const handleFileUpload = (event, val) => {
    const file = event.target.files[0]

    if (file) {
      const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg', 'webp']
      const fileExtension = file.name.split('.').pop().toLowerCase()

      if (!allowedExtensions.includes(fileExtension)) {
        toast.warn('Please upload a PNG, JPEG, JPG,webp, or SVG file.')
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        if (val === 'inputElementProfile') {
          setDataProfile(e.target.result)
        }
        if (val === 'inputElementFront') {
          setDataFrontCnic(e.target.result)
        }
        if (val === 'inputElementBack') {
          setDataBackCnic(e.target.result)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const getBranch = async () => {
    setLoading(true)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/branches`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      response.data = decryptData(response?.data?.cipher)
      const data = response.data
      const modifiedArray = data?.data?.map(item => ({
        ...item,
        label: `${item.branchName} (${item.branchCode})`,
        branchName: item.branchName,
        branchCode: item.branchCode,
      }))

      const branchCodeArr = modifiedArray.map(item => item.branchCode)

      setBranchCode(branchCodeArr)
      setBranches([
        {
          id: 'default',
          label: '--Select Branch--',
          branchName: '--Select Branch--',
        },
        ...modifiedArray,
      ])

      return modifiedArray
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getBranch()
      }
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const filterOptions = createFilterOptions({
    stringify: branchCode => `${branchCode.name} ${branchCode.code}`,
  })
  useEffect(() => {
    getBranch()
  }, [])

  useEffect(() => {
    return () => {
      formik.resetForm()
    }
  }, [])

  const handleCheck = async values => {
    let obj = {
      email: formik?.values?.email,
      password: formik?.values?.password,
    }
    obj = encryptData(obj)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Url}/user/verifyLDAPUser`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)
      if (response.status === 200) {
        if (!response?.data?.data?.name) {
          toast.warning('No user found at LDAP.')
        }
        if (response.status === 401) {
          await getNewAccessToken()
          return handleCheck()
        } else {
          toast.success(response.data?.message)
          setLDAPData(response?.data?.data)
          setCheck(true)
        }
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return handleCheck()
      }
      toast.error(error?.response?.data?.message)
      setCheck(false)
    } finally {
      setLoading(false)
    }
  }

  const getRole = async () => {
    setLoading(true)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(`${process.env.REACT_APP_Url}/role`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        await getNewAccessToken()
        return getRole()
      }
      const role = response.data
      setRoles(role?.data)
      return role?.data
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getRole()
      }
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const getCities = async () => {
    setLoading(true)
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(`${process.env.REACT_APP_Url}/city`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        await getNewAccessToken()
        return getCities()
      }
      const cities = response.data
      setCities(cities?.data)
      return cities?.data
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getCities()
      }
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const getUserById = async () => {
    setLoading(true)
    try {
      if (id) {
        if (!accessToken) {
          dispatch(logout())
          navigate('/login')
          return
        }
        const response = await axios.get(
          `${process.env.REACT_APP_Url}/user/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        response.data = decryptData(response?.data?.cipher)
        if (response.status === 401) {
          await getNewAccessToken()
          return getUserById()
        }
        const user = response.data
        setUser(user?.data)
        return user?.data
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getUserById()
      }
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserById()
    getRole()
    getCities()
  }, [])
  useEffect(() => {
    if (check && selectedRole !== 5 && LDAPData) {
      const LDAPName = LDAPData?.displayName?.trim()?.split(' ')
      // if (LDAPName && LDAPName.length > 0) {
      formik.setFieldValue('firstName', LDAPName?.[0] || '')
      formik.setFieldValue('lastName', LDAPName?.[1] || '')
      // }
    }
  }, [check, LDAPData, selectedRole])
  useEffect(() => {
    if (id && (!user || !branches || !cities || !roles)) {
      setLoader(true)
    } else {
      setLoader(false)
    }
    if (id && user) {
      formik.setFieldValue('firstName', user?.firstName)
      formik.setFieldValue('lastName', user?.lastName)

      formik.setFieldValue('city', user?.cityId?.id)
      formik.setFieldValue('cnic', user?.cnic)
      formik.setFieldValue('phone', user?.phone)
      formik.setFieldValue('email', user?.email)
      formik.setFieldValue('role', user?.role?.id)
      formik.setFieldValue('status', user?.status === 0 ? '0' : user?.status)
      formik.setFieldValue('employeeId', user?.employeeId)
      formik.setFieldValue('branch', user?.branch?.id)
      setBranchId(user?.branch?.id)
      setDataFrontCnic(
        user?.cnicFront
          ? process.env.REACT_APP_IMAGE_URL + user?.cnicFront
          : cnincFront
      )
      setDataBackCnic(
        user?.cnicBack
          ? process.env.REACT_APP_IMAGE_URL + user?.cnicBack
          : cnicBack
      )

      setDataProfile(
        user?.profileImage
          ? process.env.REACT_APP_IMAGE_URL + user?.profileImage
          : Profile
      )
    } else {
      formik.setFieldValue('firstName', '')
      formik.setFieldValue('lastName', '')
      formik.setFieldValue('city', 'default')
      formik.setFieldValue('cnic', '')
      formik.setFieldValue('phone', '')
      formik.setFieldValue('email', '')
      formik.setFieldValue('role', 'default')
      formik.setFieldValue('status', 1)
      formik.setFieldValue('employeeId', '')
      formik.setFieldValue('branch', 'default')

      setDataFrontCnic(cnincFront)
      setDataBackCnic(cnicBack)

      setDataProfile(Profile)
    }

    if (!id) {
      formik.setFieldError('firstName', '')
      formik.setFieldError('lastName', '')
      formik.setFieldError('city', '')
      formik.setFieldError('cnic', '')
      formik.setFieldError('phone', '')
      formik.setFieldError('email', '')
      formik.setFieldError('role', '')
      formik.setFieldError('status', '')
      formik.setFieldError('employeeId', '')
      formik.setFieldError('branch', '')
    }
    // if (!id) {
    //   if (buttonClicked) {
    //     formik.setFieldTouched('firstName', true)
    //     formik.setFieldTouched('lastName', true)
    //     formik.setFieldTouched('city', true)
    //     formik.setFieldTouched('cnic', true)
    //     formik.setFieldTouched('phone', true)
    //     formik.setFieldTouched('email', true)
    //     formik.setFieldTouched('role', true)
    //     formik.setFieldTouched('status', true)
    //     formik.setFieldTouched('employeeId', true)
    //     formik.setFieldTouched('branch', true)
    //   }
    // }
  }, [id, user, branches, cities, roles])
  let edit = window.location.pathname.includes('edit-user')
  const makeUserApiCall = async (obj, isEdit = false) => {
    try {
      let url = `${process.env.REACT_APP_Url}/user`
      let method = 'post'
      let data = obj

      if (isEdit) {
        method = 'put'
      }
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios({
        method,
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200 || response.status === 201) {
        toast.success(response.data?.message)
        navigate('/user')
      }
      if (response.status === 401) {
        await getNewAccessToken()
        // Retry the API call after getting a new access token
        await makeUserApiCall(obj, isEdit)
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)

      if (error.response.status === 401) {
        await getNewAccessToken()
        // Retry the API call after getting a new access token
        await makeUserApiCall(obj, isEdit)
      } else {
        toast.error(error?.response?.data?.message)
      }
    } finally {
      setLoading(false)
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: false,
    // validateOnBlur: false,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: 'default',
      password: '',
      phone: '',
      city: 'default',
      cnic: '',

      status: '',
      branch: 'default',
      employeeId: '',
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      setButtonClicked(true)
      let obj = {
        employeeId: parseInt(values.employeeId),
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        role: values?.role,
        phone: values?.phone,
        cityId:
          values?.city !== undefined && values?.city !== 'default'
            ? values?.city
            : null,
        cnic: values?.cnic || null,
        branch:
          branchId !== undefined && branchId !== 'default' ? branchId : null,
        cnicFront: dataFrontCnic || null,
        cnicBack: dataBackCnic || null,
        profileImage: dataProfile || null,
        ...(values?.role === 5 && { password: values?.password }),
      }
      let obj1 = {
        userId: id,
        // ... other fields
        firstName:
          values?.firstName !== user?.firstName ? values?.firstName : undefined,
        lastName:
          values?.lastName !== user?.lastName ? values?.lastName : undefined,
        email: values?.email !== user?.email ? values?.email : undefined,
        role:
          user?.role?.id !== values?.role
            ? values?.role
            : user?.status !== values?.status || values?.status === 1
            ? values?.role
            : undefined,
        phone: values?.phone !== user?.phone ? values?.phone : undefined,
        cityId:
          values?.city !== undefined && values?.city !== 'default'
            ? values?.city
            : null,
        cnic: values?.cnic !== user?.cnic ? values?.cnic : undefined,
        branch:
          branchId !== values?.branch
            ? values?.branch
            : branchId
            ? branchId
            : user?.branch?.id
            ? user?.branch?.id
            : branchId !== 'default'
            ? branchId
            : null,
        cnicFront:
          dataFrontCnic !== user?.cnicFront ? dataFrontCnic : undefined,
        cnicBack: dataBackCnic !== user?.cnicBack ? dataBackCnic : undefined,
        profileImage:
          dataProfile !== user?.profileImage ? dataProfile : undefined,
        status: values?.status !== user?.status ? values?.status : undefined,
      }

      if (
        values.role === 5 &&
        (!dataProfile ||
          dataProfile ===
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAADhCAAAAABXF9d3AAANmUlEQVR42u3dV2' ||
          !dataFrontCnic ||
          dataFrontCnic.includes('static') ||
          !dataBackCnic ||
          dataBackCnic.includes('static'))
      ) {
        toast.error('Select images')
      } else {
        setLoading(true)

        if (edit) {
          obj1 = encryptData(obj1)
          await makeUserApiCall(obj1, true)
        } else {
          obj = encryptData(obj)
          await makeUserApiCall(obj)
        }
      }

      setButtonClicked(false)
      setSubmitting(false)
    },
  })
  const handleWheel = e => {
    e.target.blur()
  }
  return (
    <DashboardLayout>
      <Box paddingX={mdUp ? '60px' : '8px'} paddingTop={'50px'}>
        <Box display={'flex'} alignItems={'center'} marginBottom={'40px'}>
          <ArrowBackIcon
            sx={{ marginRight: '8px', cursor: 'pointer' }}
            onClick={() => navigate('/user')}
          />
          {id ? (
            <h2>{edit ? ' Edit User' : ' User management'}</h2>
          ) : (
            <h2> Add user</h2>
          )}
        </Box>

        {id && loader ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress size={100} />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container className="ms-auto" spacing={3}>
              <Grid item xs={12} lg={9}>
                <Grid container className="ms-auto" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="email"
                      name="email"
                      type="text"
                      placeholder=" Email"
                      // disabled={!edit && id ? true : false}
                      // disabled={edit || id ?true:false}
                      disabled={
                        selectedRole !== 5 && (check || edit || id)
                          ? true
                          : false
                      }
                      label=""
                      textLabel=" Email"
                      value={formik?.values?.email || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>

                  {!id && (
                    <Grid item xs={12} md={6}>
                      <InputField
                        key={'1'}
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        disabled={selectedRole !== 5 && check ? true : false}
                        placeholder=" Password"
                        label=""
                        textLabel=" Password"
                        autoComplete="new-password"
                        value={formik?.values?.password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik?.touched?.password &&
                          Boolean(formik?.errors?.password)
                        }
                        helperText={
                          formik?.touched?.password && formik?.errors?.password
                        }
                        inputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={event => {
                                event.preventDefault()
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {!edit && selectedRole !== 5 && (
                    <Grid container align="end" justify="center">
                      <Grid item xs={12}>
                        {!edit && id ? (
                          ''
                        ) : (
                          <>
                            <InputButton
                              label="Check"
                              size={'large'}
                              loading={loading}
                              disabled={buttonClicked}
                              onClick={() => {
                                handleCheck()
                              }}
                              sx={{
                                marginTop: '0',
                                padding: '0.5rem 2rem',
                                marginRight: '12px',
                              }}
                              type="button"
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First  Name"
                      label=""
                      textLabel=" First Name"
                      // disabled={!edit && id ? true : false}
                      disabled={
                        selectedRole == 5 || formik.values.role == 5
                          ? false
                          : true
                        // selectedRole !== 5 && (check || edit || id)
                        //   ? true
                        //   : false
                      }
                      value={formik?.values?.firstName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Last  Name"
                      label=""
                      textLabel=" Last Name"
                      value={formik.values.lastName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // disabled={!edit && id ? true : false}
                      disabled={
                        selectedRole == 5 || formik.values.role == 5
                          ? false
                          : true
                        // selectedRole !== 5 && (check || edit || id)
                        //   ? true
                        //   : false
                      }
                      // error={
                      //   formik.touched.lastName &&
                      //   Boolean(formik.errors.lastName)
                      // }
                      // helperText={formik.touched.lastName && formik.errors.lastName}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="employeeId"
                      name="employeeId"
                      type="number"
                      autocomplete="employeeId"
                      placeholder="Employee Id"
                      label=""
                      disabled={id ? true : false}
                      textLabel=" Employee Id"
                      onWheel={handleWheel}
                      value={formik.values.employeeId || ''}
                      // onChange={handleEmployeeId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      readOnly
                      error={
                        formik.touched.employeeId &&
                        Boolean(formik.errors.employeeId)
                      }
                      helperText={
                        formik.touched.employeeId && formik.errors.employeeId
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSelect
                      name="role"
                      id="role"
                      optionValue="id"
                      optionName="name"
                      textLabel=" Select Role"
                      disabled={!edit && id ? true : false}
                      items={[
                        { id: 'default', name: '--Select Role--' },
                        ...(roles || []),
                      ]}
                      value={formik.values.role || ''}
                      onChange={e => {
                        formik.handleChange(e)
                        setSelectedRole(e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      edit={edit}
                      editError={formik.errors.role}
                      helperText={formik.touched.role && formik.errors.role}
                      textColor={
                        formik.values.role === 'default' ? '#C5C5C5' : 'black'
                      }
                    ></InputSelect>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder=" Contact"
                      label=""
                      textLabel=" Contact"
                      disabled={!edit && id ? true : false}
                      value={formik.values.phone || ''}
                      onChange={event => {
                        let inputPhone = event.target.value

                        // Remove non-digit characters
                        inputPhone = inputPhone.replace(/\D/g, '')
                        formik.setFieldValue('phone', inputPhone)
                        // if (inputPhone.startsWith('92')) {
                        //   if (inputPhone.length > 12) {
                        //     inputPhone = inputPhone.slice(0, 12)
                        //   }

                        //   formik.setFieldValue('phone', inputPhone)
                        // } else {
                        //   // If not, add '92' to the beginning and update formik field
                        //   if (inputPhone.length > 10) {
                        //     inputPhone = inputPhone.slice(0, 10)
                        //   }
                        //   formik.setFieldValue('phone', `92${inputPhone}`)
                        // }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputField
                      key={'1'}
                      id="cnic"
                      name="cnic"
                      type="text"
                      placeholder="CNIC"
                      label=""
                      textLabel="CNIC"
                      disabled={!edit && id ? true : false}
                      value={formik.values.cnic}
                      onChange={event => {
                        // Handle CNIC format here
                        const inputCnic = event.target.value
                        const formattedCnic = inputCnic.replace(/\D/g, '') // Remove non-digit characters

                        // Format the CNIC as desired
                        const formattedDisplayCnic = formattedCnic
                          ? `${formattedCnic.slice(0, 5)}-${formattedCnic.slice(
                              5,
                              12
                            )}-${formattedCnic.slice(12, 13)}`
                          : ''

                        // Update formik field with the formatted CNIC or empty string if it's removed
                        formik.setFieldValue('cnic', formattedDisplayCnic)
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.cnic && Boolean(formik.errors.cnic)}
                      helperText={formik.touched.cnic && formik.errors.cnic}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div></div>

                    <InputSelect
                      name="status"
                      id="status"
                      textLabel={
                        !edit && !id
                          ? ' Status (By default active)'
                          : ' Status '
                      }
                      items={[
                        { key: 1, value: 'active' },
                        { key: '0', value: 'inactive' },
                      ]}
                      optionValue="key"
                      optionName="value"
                      disabled={!edit ? true : false}
                      value={formik.values.status || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.status}
                      // error={formik.touched.status && Boolean(formik.errors.status)}
                      // helperText={formik.touched.status && formik.errors.status}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputSelect
                      name="city"
                      id="city"
                      textLabel=" City "
                      items={[
                        { id: 'default', name: '--Select City--' },
                        ...(cities || []),
                      ]}
                      optionValue="id"
                      disabled={!edit && id ? true : false}
                      optionName="name"
                      value={formik.values.city || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      textColor={
                        formik.values.city === 'default' ? '#C5C5C5' : 'black'
                      }
                      editError={formik.errors.city}
                      edit={edit}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="branch"
                      filterOptions={filterOptions}
                      name="branch"
                      options={branches}
                      value={formik.values.branch || ''}
                      sx={{ width: 300, borderRadius: '20px' }}
                      textLabel=" Select Branch"
                      data={branches}
                      // setBranchError={setBranchError}
                      disabled={!edit && id ? true : false}
                      setBranchId={id => {
                        formik.setFieldValue('branch', id)
                        setBranchId(id)
                      }}
                      // error={formik.touched.branch && Boolean(formik.errors.branch)}
                      // helperText={formik.touched.branch && formik.errors.branch }

                      error={
                        formik.touched.branch && Boolean(formik.errors.branch)
                      }
                      helperText={formik.touched.branch && formik.errors.branch}
                      editPage={id}
                      editError={formik.errors.branch}
                      edit={edit}
                    />
                    <Box
                      sx={{
                        fontSize: ' 0.75rem;',
                        color: '#d32f2f',
                        fontWeight: '400',
                      }}
                      marginLeft={'10px'}
                    >
                      {/* {branchError} */}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                  <Select2Wrapper
                    items={cities}
                    name="city"
                    id="city"
                    textLabel=" City "
                    value={formik.values.city || ''}
                    disabled={id ? true : false}
                    optionName="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid> */}
                  {!id && <Grid item xs={12} md={6}></Grid>}
                  <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
                    <label>Upload CNIC Front Picture</label>
                    <input
                      type="file"
                      id="cnicFrontPicture"
                      name="cnicFrontPicture"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={inputElementFront}
                      onChange={e => handleFileUpload(e, 'inputElementFront')}
                      error={
                        formik.touched.cnicFrontPicture &&
                        Boolean(formik.errors.cnicFrontPicture)
                      }
                      helperText={
                        formik.touched.cnicFrontPicture &&
                        formik.errors.cnicFrontPicture
                      }
                    />

                    <img
                      color="secondary"
                      variant="contained"
                      component="span"
                      onClick={() =>
                        id && !edit ? '' : inputElementFront?.current?.click()
                      }
                      src={dataFrontCnic && dataFrontCnic}
                      style={{
                        width: '300px',
                        height: '155pt',
                        cursor: 'pointer',
                        marginTop: '20px',
                      }}
                      alt="img"
                    />
                    {id && !edit ? (
                      ''
                    ) : (
                      <Box
                        className={classes.editCircle}
                        style={{ left: '240px' }}
                        onClick={() =>
                          id && !edit ? '' : inputElementFront?.current?.click()
                        }
                      >
                        <EditIcon style={{ width: '15px' }} />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
                    <label>Upload CNIC Back Picture</label>
                    <input
                      type="file"
                      id="cnicBackPicture"
                      name="cnicBackPicture"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={inputElementBack}
                      onChange={e => handleFileUpload(e, 'inputElementBack')}
                    />

                    <img
                      color="secondary"
                      variant="contained"
                      component="span"
                      src={dataBackCnic}
                      style={{
                        cursor: 'pointer',
                        width: '300px',
                        height: '155pt',

                        marginTop: '20px',
                      }}
                      alt="img"
                      onClick={() =>
                        id && !edit ? '' : inputElementBack?.current?.click()
                      }
                    />
                    {id && !edit ? (
                      ''
                    ) : (
                      <Box
                        className={classes.editCircle}
                        style={{ left: '240px' }}
                        onClick={() =>
                          id && !edit ? '' : inputElementBack?.current?.click()
                        }
                      >
                        <EditIcon style={{ width: '15px' }} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={2} lg={2}>
                <input
                  ref={inputElementProfile}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={e => handleFileUpload(e, 'inputElementProfile')}
                />

                <img
                  color="secondary"
                  variant="contained"
                  component="span"
                  src={dataProfile}
                  style={{
                    width: '175px',
                    height: '155px',
                    cursor: 'pointer',
                  }}
                  alt="img"
                />
                {id && !edit ? (
                  ''
                ) : (
                  <Box
                    className={classes.editCircle}
                    style={{ left: '130px' }}
                    onClick={() =>
                      id && !edit ? '' : inputElementProfile?.current?.click()
                    }
                  >
                    <EditIcon style={{ width: '15px' }} />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container align="center" justify="center" marginY={'30px'}>
              <Grid item xs={12}>
                {!edit && id ? (
                  ''
                ) : (
                  <>
                    <InputButton
                      label="Cancel"
                      size={'large'}
                      sx={{
                        marginTop: '0',
                        padding: '0.5rem 2rem',
                        marginRight: '12px',
                      }}
                      onClick={() => navigate('/user')}
                    />
                    <InputButton
                      label="Save"
                      size={'large'}
                      loading={loading}
                      disabled={buttonClicked}
                      sx={{
                        marginTop: '0',
                        padding: '0.5rem 2rem',
                        marginRight: '12px',
                      }}
                      type="submit"
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </DashboardLayout>
  )
}

export default AddUser
