import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  sideBar: {
    '&.MuiDrawer-root': {
      width: `${theme.drawerWidth}px`,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: `${theme.drawerWidth}px`,
        boxSizing: 'border-box',
      },
      '& .MuiList-root': {
        paddingTop: '0',
      },
    },
  },
  sideBarLogoList: {
    '&.MuiListItem-root': {
      '& .MuiListItemButton-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiAvatar-root': {
          height: '50px',
          width: '150px',
          borderRadius: '0',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
        },
      },
    },
  },
  sideBarMenu: {
    '&.MuiList-root': {
      marginTop: '2rem',
      paddingTop: '0',
      '& .MuiButtonBase-root': {
        padding: '1rem 1.5rem',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '20px',
        '& .MuiAvatar-root': {
          height: '20px',
          width: '20px',
          borderRadius: '0',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
        },
      },
      '& .MuiListItemText-root': {
        marginLeft: '1.5rem',
        '& .MuiTypography-root': {
          fontSize: '1rem',
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
      },
    },
  },
}))

export default useStyles
