export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const setAccessToken = (token) => ({
  type: ACCESS_TOKEN,
  payload: token,
});

export const setRefreshToken = (token) => ({
  type: REFRESH_TOKEN,
  payload: token,
});
