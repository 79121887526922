import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/Dashboard'
import UserTable from '../../components/common/Table'
import { Box } from '@mui/material'

import Pagination from '../../components/common/Pagination'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/action/loginActions'
import { setAccessToken, setRefreshToken } from '../../redux/action/tokenAction'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { decryptData, encryptData } from '../../services/EncryptTemplate'
import { toast } from 'react-toastify'
const User = () => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const [userColumn, setUserColumn] = useState([])
  const [searchText, setSearchText] = useState('')
  const [firstPage, setFirstPage] = useState(1)
  const [lastPage, setLastPage] = useState(25)
  const [count, setCount] = useState(0)
  const [data, setData] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      if (res.status === 200) {
        res.data = decryptData(res?.data?.cipher)
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }

  const getUsers = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(`${process.env.REACT_APP_Url}/user`, {
        params: {
          searchText,
          pageNumber: firstPage,
          perPageRecords: 25,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: status => status < 500, // Accept only responses with status less than 500
      })

      response.data = decryptData(response?.data?.cipher)
      if (response.status === 200) {
        const data = response.data
        setCount(data?.data?.count)
        setData(data?.data?.users)
      } else if (response.status === 401) {
        await getNewAccessToken()
        return getUsers()
      } else {
        throw new Error('Failed to fetch users')
      }
    } catch (error) {
      error.response.data = decryptData(error?.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getUsers()
      }
      console.error('Error fetching users:', error)
    }
  }
  // const {
  //   isLoading: loadingUsers,
  //   error,
  //   data,
  // } = useQuery({
  //   queryKey: ['data', searchText, firstPage, lastPage],
  //   queryFn: getUsers,
  // })

  useEffect(() => {
    getUsers()
  }, [firstPage, lastPage, searchText])

  useEffect(() => {
    const columns = [
      {
        name: ' Employee ID',
        id: 'employeeId',
        selector: row => row?.requestId,
        sortable: true,
      },
      {
        name: 'First Name',
        id: 'firstName',
        selector: row => row?.remitterName,
        sortable: true,
      },
      {
        name: 'Last Name',
        id: 'lastName',
        selector: row => row?.remitterName,
        sortable: true,
      },
      {
        name: 'Email',
        id: 'email',
        selector: row => row?.beneficiaryName,
        sortable: true,
      },
      {
        name: 'Branch',
        id: 'branch',
        selector: row => row?.branch?.branchName,
        sortable: true,
      },
      {
        name: 'City',
        id: 'city',
        selector: row => row?.cityId?.name,
        sortable: true,
      },

      {
        name: 'Status',
        id: 'user-status',
        selector: row => row.status,
        sortable: true,
      },
      {
        name: 'Role',
        id: 'role',
        selector: row => row.role.key,
        sortable: true,
      },
      {
        name: 'Action ',
        id: 'userAction',
        selector: row => row.action,
        sortable: true,
      },
    ]

    setUserColumn(columns)
  }, [])

  const handActionView = row => {
    navigate(`/add-user/${row}`)
  }
  const handActionEdit = row => {
    navigate(`/edit-user/${row}`)
  }
  const handlePre = () => {
    if (firstPage > 1) {
      setFirstPage(firstPage - 1)
      setLastPage(lastPage - 25)
    }
  }
  const handleNext = () => {
    if (lastPage < count) {
      setFirstPage(firstPage + 1)
      setLastPage(lastPage + 25)
    }
  }
  return (
    <>
      <DashboardLayout>
        <Box marginTop={'50px'}>
          <UserTable
            tableHeading={userColumn}
            tableData={data}
            isSearch={true}
            // isFilteration={true}
            isAdvanceSearch={false}
            // tableButton={true}
            // loading={loadingUsers}
            isPagination={true}
            sortServer={false}
            // buttonText={'Filter'}
            handActionView={handActionView}
            handActionEdit={handActionEdit}
            title="User Management "
            value={searchText}
            setSearchText={setSearchText}
          />

          <Pagination
            start={firstPage}
            end={lastPage}
            total={count}
            handleNext={handleNext}
            handlePre={handlePre}
          />
        </Box>
      </DashboardLayout>
    </>
  )
}

export default User
