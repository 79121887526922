import React, { useState } from 'react'
import { Box, Typography, Grid, Paper } from '@mui/material'
import InputField from '../../components/common/InputField'
import DashboardLayout from '../../layouts/Dashboard'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useStyles from '../../styles/DatePickerStyles'
import InputButton from '../../components/common/InputButton'

import moment from 'moment'
import { useFormik } from 'formik'
import UserTable from '../../components/common/Table'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { setAccessToken } from '../../redux/action/tokenAction'
import { logout } from '../../redux/action/loginActions'
import axios from 'axios'
import { decryptData, encryptData } from '../../services/EncryptTemplate'

const SearchInbox = () => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const [firstPage, setFirstPage] = useState(1)
  const [data, setData] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const role = sessionStorage.getItem('role')
  const statusId = data.find(data => data)?.statusId
  const dispatch = useDispatch()
  const getStatus = statusId => {
    switch (role) {
      case 'maker':
        switch (statusId) {
          case null:
          case 2:
          case 14:
            return true

          case 1:
          case 3:
            return false

          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
            return false
          // default:
          //   return 'Unknown'
        }

      case 'checker':
        switch (statusId) {
          case 1:
          case 3:
            return true
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
            return false
          // default:
          //   return false
        }

      case 'teller':
        switch (statusId) {
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
            return true
          // default:
          //   return 'Unknown'
        }

      // default:
      //   return 'Unknown role'
    }
  }
  const columns = [
    {
      id: 'transactionReference',
      name: 'Transaction Reference',
      selector: row => row.transactionReference,
      sortable: true,
      minWidth: 50,
      align: 'left',
    },

    {
      id: 'remitterName',
      name: 'Remitter Name',
      selector: row => row.remitterName,
      sortable: true,
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'beneficiaryName',
      name: 'Beneficiary Name',
      selector: row => row.beneficiaryName,
      sortable: true,
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'statusId',
      name: 'Status',
      selector: row => row.statusId,
      sortable: true,
      align: 'left',
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      align: 'left',
    },
  ]
  const status = getStatus(statusId)
  if (status && (role === 'maker' || role === 'checker' || role === 'teller')) {
    columns.push({
      id: 'action',
      name: 'Action ',
      selector: row => row.action,
      sortable: true,
      align: 'left',
    })
  }

  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      res.data = decryptData(res?.data?.cipher)
      if (res.status === 200) {
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }

  const makeRemittanceSearchRequest = async params => {
    const url = `${process.env.REACT_APP_Url}/remittance/search`

    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      })

      response.data = decryptData(response?.data?.cipher)

      if (response.status === 200) {
        const data = response.data
        if (data?.data?.length === 0) {
          toast.warn('No Record Found.')
          setData([])
        } else {
          toast.success(data?.message)
          setData(data.data)
        }
      }
      if (response.status === 401) {
        await getNewAccessToken()
        await makeRemittanceSearchRequest(params)
      }
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        await makeRemittanceSearchRequest(params)
      } else {
        toast.error(error?.response?.data?.message || 'An error occurred')
      }
    } finally {
      setLoading(false)
      setButtonClicked(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      transactionReference: '',
      deliveryDate: null,
      assignBranch: null,
      comment: '',
      beneficiaryCNIC: '',
      beneficiaryContact: '',
      requestNumber: '',
    },

    onSubmit: async (values, error, errors) => {
      setLoading(true)
      setButtonClicked(true)

      const obj = {
        transactionReference: formik.values?.transactionReference,
        beneficiaryCNIC: formik.values.beneficiaryCNIC,
        beneficiaryContact: formik.values.beneficiaryContact,
        requestNumber: formik.values.requestNumber,
        deliveryDate: formik.values?.deliveryDate?.$d
          ? moment(formik.values?.deliveryDate?.$d).format()
          : '',
      }

      if (
        !obj.beneficiaryCNIC &&
        !obj.beneficiaryContact &&
        !obj.transactionReference &&
        !obj.requestNumber &&
        !obj.deliveryDate
      ) {
        setLoading(false)
        setButtonClicked(false)
        toast.warning('Search criteria not defined!')
        return
      }

      const params = {
        beneficiaryCNIC: obj.beneficiaryCNIC,
        beneficiaryContact: obj.beneficiaryContact,
        requestNumber: obj.requestNumber,
        deliveryDate:
          obj.deliveryDate &&
          moment(obj.deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        transactionReference: obj.transactionReference,
        pageNumber: firstPage,
        numberOfRecordsPerPage: 7,
      }

      await makeRemittanceSearchRequest(params)
    },
  })

  const handleShowRemittance = id => {
    navigate(`/inbox-detail?id=${id}`)
  }
  return (
    <DashboardLayout>
      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ borderRadius: '12px', marginTop: '20px' }}>
          <Box
            bgcolor={'#CCCCCC'}
            sx={{ borderRadius: '12px 12px 0 0' }}
            paddingY="10px"
          >
            {' '}
            <Typography color="#074F93" variant="h4" marginLeft={'12px'}>
              Search Request Status
            </Typography>
          </Box>
          <Box sx={{ padding: '25px' }}>
            <Grid container className="ms-auto" spacing={2}>
              <Grid item xs={12} md={4}>
                {' '}
                <InputField
                  key={'1'}
                  id="beneficiaryCNIC"
                  name="beneficiaryCNIC"
                  type="text"
                  placeholder="Beneficiary CNIC"
                  label=""
                  textLabel="Beneficiary CNIC"
                  value={formik.values.beneficiaryCNIC || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.beneficiaryCNIC &&
                    Boolean(formik.errors.beneficiaryCNIC)
                  }
                  helperText={
                    formik.touched.beneficiaryCNIC &&
                    formik.errors.beneficiaryCNIC
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {' '}
                <InputField
                  key={'1'}
                  id="beneficiaryContact"
                  name="beneficiaryContact"
                  type="text"
                  placeholder="Beneficiary Contact"
                  label=""
                  textLabel="Beneficiary Contact"
                  value={formik.values.beneficiaryContact || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.beneficiaryContact &&
                    Boolean(formik.errors.beneficiaryContact)
                  }
                  helperText={
                    formik.touched.beneficiaryContact &&
                    formik.errors.beneficiaryContact
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {' '}
                <InputField
                  key={'1'}
                  id="requestNumber"
                  name="requestNumber"
                  type="text"
                  placeholder="request Number"
                  label=""
                  textLabel="Request Number"
                  value={formik.values.requestNumber || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.requestNumber &&
                    Boolean(formik.errors.requestNumber)
                  }
                  helperText={
                    formik.touched.requestNumber && formik.errors.requestNumber
                  }
                />
              </Grid>
              {role !== 'maker' && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  {' '}
                  <label
                    style={{
                      fontSize: '12px',
                      marginLeft: '10px',
                      marginBottom: '4px',
                      color: '#074F93',
                    }}
                  >
                    Delivery Date
                  </label>
                  <LocalizationProvider
                    className={classes.inputField}
                    dateAdapter={AdapterDayjs}
                    style={{ borderRadius: '100px', width: '400px' }}
                  >
                    {/* <DemoContainer components={['DatePicker']}> */}
                    <DatePicker
                      className={classes.inputField}
                      style={{ borderRadius: '100px', width: '400px' }}
                      key={'1'}
                      id="deliveryDate"
                      placeholder="Delivery Date"
                      textLabel=" Delivery Date"
                      value={formik?.values?.deliveryDate || ''}
                      onChange={date =>
                        formik.setFieldValue('deliveryDate', date)
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.deliveryDate &&
                        Boolean(formik.errors.deliveryDate)
                      }
                      helperText={
                        formik.touched.deliveryDate &&
                        formik.errors.deliveryDate
                      }
                    />
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                {' '}
                <InputField
                  key={'1'}
                  id="transactionReference"
                  name="transactionReference"
                  type="text"
                  placeholder="Transaction Reference"
                  label=""
                  textLabel="Transaction Reference"
                  value={formik.values.transactionReference || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.transactionReference &&
                    Boolean(formik.errors.transactionReference)
                  }
                  helperText={
                    formik.touched.transactionReference &&
                    formik.errors.transactionReference
                  }
                />
              </Grid>
            </Grid>
            <Grid container align="center" justify="center" marginY={'30px'}>
              <Grid item xs={12}>
                {/* <InputButton
                  size={'small'}
                  sx={{
                    marginTop: '0',
                    padding: '0.5rem 2rem',
                    marginRight: '12px',
                  }}
                  disabled={true}
                  label="Cancel"
                /> */}
                <InputButton
                  size={'small'}
                  sx={{
                    marginTop: '0',
                    padding: '0.5rem 2rem',
                    marginRight: '12px',
                  }}
                  disabled={buttonClicked}
                  loading={loading}
                  type="submit"
                  label="Search"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
      <Box marginTop={'50px'}>
        <>
          {/* {data.length > 0 ? (<> */}
          <UserTable
            tableHeading={columns}
            tableData={data}
            isAdvanceSearch={false}
            isPagination={true}
            sortServer={false}
            buttonText={'Upload Transactions'}
            handleShowRemittance={handleShowRemittance}
          />

          {/* </>) : (<p>No Record Found</p>)} */}
          {/* <Pagination
            start={firstPage}
            end={lastPage}
            total={count}
            handleNext={handleNext}
            handlePre={handlePre}
          /> */}
        </>
        {/* )} */}

        {/* <InputButton label="Alert" onClick={() => setOpen(true)} />
      {open && (
        <Popup
          open={open}
          setOpen={setOpen}
          type="Error"
          detail="No Record Found"
        />
      )} */}
      </Box>
    </DashboardLayout>
  )
}

export default SearchInbox
