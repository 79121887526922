import React, { useState } from 'react'
import { Avatar, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MuiAppBar from '@mui/material/AppBar'
import useStyles from '../../styles/TopBarStyles'
import MenuToggle from '../../assets/menu-toggle.svg'
import LogoutIcon from '../../assets/logout-icon.svg'
import PageName from './PageName'

import { Menu, MenuItem } from '@mui/material'
import { logout } from '../../redux/action/loginActions'
import { useDispatch } from 'react-redux'
const Topbar = ({ open, setOpen, pageName }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open1 = Boolean(anchorEl)
  const dispatch = useDispatch();
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogOut = () => {
    window. sessionStorage.clear()
    dispatch(logout())
     sessionStorage.removeItem('user');
    sessionStorage.removeItem('user')
  }

  return (
    <MuiAppBar
      className={`${classes.topBar} ${classes.dashboardTopBar} ${open ? classes.topBarOpen : ''
        }`}
      position="fixed"
    >
      <Toolbar>
        <Avatar
          alt="Alert Icon"
          src={MenuToggle}
          className={classes.toggleIcon}
          onClick={() => setOpen(!open)}
        />
        <PageName name={pageName} />

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open1}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
        <Avatar
          alt="Logout Icon"
          src={LogoutIcon}
          className={classes.logoutIcon}
          onClick={handleClick}
        />
      </Toolbar>
    </MuiAppBar>
  )
}

export default Topbar
