import { Typography } from '@mui/material'
import React from 'react'
import useStyles from '../../styles/TopBarStyles'

const PageName = ({ name }) => {
  const classes = useStyles()
  let Pname = name
  return (
    <Typography
      variant="h6"
      className={classes.pageName}
      noWrap
      component="div"
      sx={{ textTransform: 'capitalize' }}
    >
      {Pname}
    </Typography>
  )
}

export default PageName
