import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  dashboardTopBar: {
    '& .MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
    },
  },
  topBar: {
    '&.MuiAppBar-root': {
      boxShadow: `0px 3px 6px rgba(0, 0, 0, 0.16)`,
    },
  },
  topBarOpen: {
    '&.MuiAppBar-root': {
      width: `calc(100% - ${theme.drawerWidth}px) !important`,
    },
  },
  pageName: {
    '&.MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: theme.palette.text.light,
    },
  },
  toggleIcon: {
    '&.MuiAvatar-root': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
      borderRadius: '0',
      objectFit: 'contain',
      height: '20px',
      width: '30px',
      '& .MuiAvatar-img': {
        objectFit: 'contain',
      },
    },
  },
  logoutIcon: {
    '&.MuiAvatar-root': {
      marginRight: theme.spacing(2),
      cursor: 'pointer',
      borderRadius: '0',
      objectFit: 'contain',
      height: '22px',
      width: '22px',
      '& .MuiAvatar-img': {
        objectFit: 'contain',
      },
    },
  },
}))

export default useStyles
