import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import EditIcon from '@mui/icons-material/Edit'

const useStyles = makeStyles(theme => ({
  formControl: {
    '& .MuiInputBase-root': {
      // border: `1px solid ${theme.palette.colorsName.darkSilver}`,
      borderRadius: '100px',
      // minWidth: '18rem',
      fontWeight: 100,
      justifyContent: 'center',
      fontSize: '12px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0px',
    },
  },
  select: {
    width: 'auto',
    fontSize: '12px',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    position: 'relative',
    color: '#6EC177',
    fontSize: '14px',
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#074F93',
    },
    '& li.Mui-selected:hover': {
      background: '#074F93',
    },
  },
}))

const AutocompleteComp = ({
  data,
  textLabel,
  icon,
  setBranchId,
  name,
  value,
  id,
  disabled,
  onChange,
  error,
  onBlur,
  helperText,
  setBranchError,
  editPage,
  editError,
  edit,
}) => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = useState(null)
  useEffect(() => {
    if (value && data.length) {
      const selectedvalue = data?.find(item => item?.id === value)
      setBranchId(selectedValue?.id||value)
      setSelectedValue(selectedvalue)
    }
  }, [data, value])
  useEffect(() => {
    if (editPage === undefined) {
      setBranchId(null)
      setSelectedValue(null)
    }
  }, [editPage])

  const handleOnChange = (event, values) => {
    setSelectedValue(values)
    setBranchId(values?.id)
  }
  return (
    <div>
      {textLabel && (
        <Box display={'flex'} alignItems={'center'}>
          <label
            style={{
              fontSize: '12px',
              marginLeft: '10px',
              marginBottom: '4px',
              color: '#074F93',
            }}
          >
            {textLabel}
          </label>
          <Box ml="5px">
            {icon === 'editIcon' && <EditIcon style={{ width: '15px' }} />}
          </Box>
        </Box>
      )}
      <Autocomplete
        disablePortal
        options={data ? data : []}
        defaultValue={selectedValue || 'default'}
        id={id}
        name={name}
        error={error}
        editError={editError}
        edit={edit}
        value={selectedValue}
        disabled={disabled}
        onChange={handleOnChange}
        helperText={helperText}
        className={classes.formControl}
        sx={{
          '& .MuiInputBase-root': {
            color: selectedValue?.id == 'default' ? '#C5C5C5' : 'black',
          },
          // Add more custom styles as needed
        }}
        // sx={{
        //   '& .MuiInputBase-root': {
        //     color:selectedValue?id == "default " ? '#C5C5C5' : 'black',

        //   },
        // }}
        renderInput={params => <TextField {...params} />}
      />
      {edit && (
        <p
          style={{
            color: '#d32f2f',
            lineHeight: '1.5',
            fontWeight: '400',
            fontSize: '0.75rem',
            textAlign: 'left',
            marginTop: '3px',
            marginRight: '14px',
            marginBottom: '0',
            marginLeft: '14px',
          }}
        >
          {editError}
        </p>
      )}
      {!edit && (
        <p
          style={{
            color: 'rgb(211, 47, 47)',
            marginLeft: '16px',
            marginTop: '3px',
            fontSize: '10px',
            fontWeight: 200,
          }}
        >
          {helperText && helperText}
        </p>
      )}
    </div>
  )
}

export default AutocompleteComp
