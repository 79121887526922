import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import InputField from '../components/common/InputField'
import InputButton from '../components/common/InputButton'
import loginLogo from '../assets/bank-logo-white.svg'
import loginIcon from '../assets/login-icon.svg'
import useStyles from '../styles/LoginScreenStyles'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { loginSuccess, storeUserData } from '../redux/action/loginActions'
import { decryptData, encryptData } from '../services/EncryptTemplate'

const validationSchema = yup.object({
  email: yup.string('Enter your email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
})

const Login = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);

  let inactivityTimeout = null;

  const logoutUser = () => {
    sessionStorage.clear();
    dispatch(storeUserData(null));
    navigate('/login');
    if (inactivityTimeout){ 
      toast.warning('You have been logged out due to inactivity.');
      clearTimeout(inactivityTimeout);}
  };

  const resetTimer = () => {
    if (inactivityTimeout) clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(logoutUser, 5 * 60 * 1000); // 15 minute
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      dispatch(storeUserData(JSON.parse(storedUser)));
      resetTimer();

      const events = ['mousemove', 'keypress', 'scroll', 'click'];
      events.forEach((event) => window.addEventListener(event, resetTimer));

      return () => {
        if (inactivityTimeout) {
          clearTimeout(inactivityTimeout);
        events.forEach((event) => window.removeEventListener(event, resetTimer));
        }
      };
    }
  }, [dispatch]);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      dispatch(storeUserData(JSON.parse(storedUser)));
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setLoading(true)
      let obj = {
        email: values.email,
        password: values.password,
      }
      obj=encryptData(obj)
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_Url}/auth/login`,
          obj
        )
        if (res.status === 200) {
          res.data=decryptData(res?.data?.cipher)
          dispatch(loginSuccess(res?.data?.data));
          sessionStorage.setItem('user', JSON.stringify(res?.data?.data));
          toast.success(res.data?.message);
          sessionStorage.setItem('login', true);
          sessionStorage.setItem('userId', res.data.data.id);
          sessionStorage.setItem('role', res.data.data.role.key);
          sessionStorage.setItem('token', res.data.data.accessToken);
          sessionStorage.setItem('refreshToken', res.data.data.refreshToken);
          sessionStorage.setItem('isNewLogin', '1');
          if (res.data.data.role.key === 'superAdmin') {
            navigate('/user');
          } else {
            navigate('/inbox');
          }
          resetTimer(); // Start the inactivity timer after a successful login
        }
      } catch (error) {
        error.response.data=decryptData(error?.response?.data?.cipher)
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Box className={classes.loginScreen}>
        <Avatar
          alt="JS Bank Logo"
          className={classes.loginBankLogo}
          src={loginLogo}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={11} sm={8} md={8} lg={6} xl={5}>
            <Paper className={classes.loginInnerScreen}>
              <Avatar
                alt="Login Screen Icon"
                className={classes.loginIcon}
                src={loginIcon}
              />
              <Typography
                className={classes.mainHeading}
                gutterBottom
                variant="body1"
                marginBottom={'2rem'}
              ></Typography>
              <form onSubmit={formik.handleSubmit}>
                {/* This input field is only to fix the browser auto fill issue */}
                <input
                  type="email"
                  name="email"
                  value=""
                  readOnly={true}
                  hidden
                />
                <InputField
                  fullWidth
                  size={'small'}
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <InputField
                  fullWidth
                  size={'small'}
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  inputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={event => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Box textAlign={'center'}>
                  <InputButton
                    size={'medium'}
                    color={'primary'}
                    variant={'contained'}
                    type="submit"
                    loading={loading}
                    label={'Login'}
                    sx={{ marginTop: '0', padding: '0.5rem 1.5rem' }}
                  />
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Login