import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '400px',
}

const center = {
  lat: 33.6192718,
  lng: 73.166999,
}

function GoogleMapComp({ remittanceData = [] }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_Map_Key}`,
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(
    function callback(map) {
      if (Array.isArray(remittanceData) && remittanceData.length > 0) {
        const bounds = new window.google.maps.LatLngBounds()
        remittanceData.forEach(remittance => {
          bounds.extend({
            lat: parseFloat(remittance.latitude),
            lng: parseFloat(remittance.longitude),
          })
        })
        map.fitBounds(bounds)
      }
      setMap(map)
    },
    [remittanceData]
  )

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {Array.isArray(remittanceData) &&
        remittanceData.map((remittance, index) => (
          <Marker
            key={index}
            position={{
              lat: parseFloat(remittance.latitude),
              lng: parseFloat(remittance.longitude),
            }}
          />
        ))}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(GoogleMapComp)
