export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const STORE_USER_DATA = 'STORE_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';

export const loadUserData = () => ({
  type: LOAD_USER_DATA,
});
export const loginRequest = () => ({
    type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});


export const logout = () => ({
    type: LOGOUT,
});

export const storeUserData = (user) => ({
    type: STORE_USER_DATA,
    payload: user,
  });
  
  export const removeUserData = () => ({
    type: REMOVE_USER_DATA,
  });