import React from 'react'
import { TextField, Box } from '@mui/material'
import useStyles from '../../styles/InputFieldStyles'
import EditIcon from '@mui/icons-material/Edit'
const InputField = ({
  fullWidth,
  type,
  id,
  name,
  label,
  value,
  placeholder,
  variant,
  color,
  size,
  margin,
  required,
  disabled,
  multiline,
  rows,
  minRows,
  maxRows,
  autoComplete,
  autoFocus,
  onChange,
  onBlur,
  error,
  helperText,
  inputProps,
  textLabel,
  icon,
  onWheel,
}) => {
  const classes = useStyles()
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        {textLabel && (
          <Box display={'flex'} alignItems={'center'}>
            {' '}
            <label
              style={{
                fontSize: '12px',
                marginLeft: '10px',
                marginBottom: '4px',
                color: '#074F93',
              }}
            >
              {textLabel}
            </label>
            <Box ml="5px">
              {' '}
              {icon === 'editIcon' && <EditIcon style={{ width: '15px' }} />}
            </Box>
          </Box>
        )}
        <TextField
          fullWidth={fullWidth}
          type={type}
          id={id}
          name={name}
          label={label}
          value={value}
          placeholder={placeholder}
          variant={variant}
          color={color}
          className={classes.inputField}
          size={size}
          margin={margin}
          required={required}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          InputProps={inputProps}
          onWheel={onWheel}
        />
        {error && (
          <p
            style={{
              color: '#d32f2f',
              lineHeight: '1.5',
              fontWeight: '400',
              fontSize: '0.75rem',
              textAlign: 'left',
              marginTop: '-24px',
              marginRight: '14px',
              marginBottom: '0',
              marginLeft: '14px',
            }}
          >
            {error}
          </p>
        )}
      </Box>
    </>
  )
}

export default InputField
