import * as React from 'react'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import TextField from '@material-ui/core/TextField'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
const theme = createMuiTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },
})
const InputTextarea = ({
  fullWidth,
  type,
  id,
  name,
  label,
  value,
  placeholder,
  variant,
  color,
  size,
  margin,
  required,
  disabled,
  multiline,
  rows,
  minRows,

  onChange,
  onBlur,
  error,
  helpertext,
  textLabel,
  icon,
}) => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        {textLabel && (
          <Box display={'flex'} alignItems={'center'}>
            <label
              style={{
                fontSize: '12px',
                marginLeft: '10px',
                marginBottom: '4px',
                color: '#074F93',
              }}
            >
              {textLabel}
            </label>
            <Box ml="5px">
              {' '}
              {icon === 'editIcon' && <EditIcon style={{ width: '15px' }} />}
            </Box>
          </Box>
        )}{' '}
        <TextField
          fullWidth
          multiline
          // className={classes.inputField}
          InputProps={{
            rows: 3,
          }}
          value={value}
          onChange={onChange}
          maxRows={8}
          aria-label="maximum height"
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          variant={variant}
          color={color}
          size={size}
          margin={margin}
          required={required}
          disabled={disabled}
          rows={rows}
          minRows={minRows}
          onBlur={onBlur}
          error={error}
          helpertext={helpertext}
        />
      </MuiThemeProvider>
    </>
  )
}
export default InputTextarea
