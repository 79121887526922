import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/Dashboard'
import { Box, Typography, Grid, Paper } from '@mui/material'
import InputSelectFeild from '../../components/common/InputSelectFeild'
import InputButton from '../../components/common/InputButton'
import InputField from '../../components/common/InputField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from '../../styles/DatePickerStyles'
import { useTheme } from '@mui/material/styles'
import { toast } from 'react-toastify'
import Popup from '../../components/common/Popup'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import Autocomplete from '../../components/common/Autocomplete'
import { format } from 'date-fns'
import moment from 'moment'
import dayjs from 'dayjs'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/action/loginActions'
import { useNavigate } from 'react-router-dom'
import { setAccessToken } from '../../redux/action/tokenAction'
import { decryptData, encryptData } from '../../services/EncryptTemplate'

const validationSchema = yup.object({
  requestID: yup.string(''),
  reportType: yup.string().required('Report Type is required'),
})
const Reports = () => {
  let { accessToken, refreshToken, user } = useSelector(state => state.login)
  const [data, setData] = useState([])
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(true)
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  const [isFormChanged, setIsFormChanged] = useState(false)
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('lg'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const role = sessionStorage.getItem('role')
  const getNewAccessToken = async () => {
    if (!refreshToken) {
      dispatch(logout())
      navigate('/login')
      return
    }
    let obj = {
      userId: user?.id,
      refreshToken,
    }
    obj = encryptData(obj)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/auth/refreshToken`,
        obj
      )
      res.data = decryptData(res?.data?.cipher)
      if (res.status === 200) {
        const { accessToken: newAccessToken } = res?.data?.data
        sessionStorage.setItem('token', newAccessToken)
        dispatch(setAccessToken(newAccessToken))
        accessToken = newAccessToken
        return
      } else {
        accessToken = null

        dispatch(logout())
        navigate('/login')
        return
      }
    } catch (error) {
      accessToken = null

      console.error('Failed to refresh token:', error)
      dispatch(logout())
      navigate('/login')
    }
  }

  const getReportsData = async (fromDate, toDate) => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const queryParams = []
      if (fromDate) queryParams.push(`fromDate=${fromDate}`)
      if (toDate) queryParams.push(`toDate=${toDate}`)
      const queryString = queryParams.join('&')
      const url = `${process.env.REACT_APP_Url}/user/report?${queryString}`

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      response.data = decryptData(response?.data?.cipher)
      const data = response.data
      if (response.status === 200) {
        setData(data.data)
        setSuccess(true)
        if (submitClicked) {
          if (!data?.data || data?.data?.length === 0) {
            setButtonClicked(true)
            toast.warning('No record found.')
            setSuccess(true)
          } else {
            toast.success('Your report is ready to download.')
            setSuccess(true)
            setButtonClicked(false)
            setSubmitClicked(false)
          }
        }
      } else if (response.status === 401) {
        await getNewAccessToken()
        return getReportsData(fromDate, toDate) // Pass the original params again.
      }
    } catch (error) {
      setSuccess(false)
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getReportsData(fromDate, toDate) // Pass the original params again.
      }
      toast.error(
        error?.response?.data?.message || 'Error fetching report data'
      )
    } finally {
      setLoading(false)
    }
  }

  const getRemmitanceReport = async (fromDate, toDate, requestId, branch) => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const queryParams = []
      if (fromDate) queryParams.push(`fromDate=${fromDate}`)
      if (toDate) queryParams.push(`toDate=${toDate}`)
      if (requestId) queryParams.push(`requestId=${requestId}`)
      if (branch) queryParams.push(`branch=${branch}`)
      const queryString = queryParams.join('&')
      const url = `${process.env.REACT_APP_Url}/remittance/report?${queryString}`

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      response.data = decryptData(response?.data?.cipher)

      const data = response.data
      if (response.status === 200) {
        setData(data.data)
        setSuccess(true)
        if (submitClicked) {
          if (!data?.data || data?.data?.length === 0) {
            setButtonClicked(true)
            toast.warning('No record found.')
            setSuccess(true)
          } else {
            toast.success('Your report is ready to download.')
            setSuccess(true)
            setButtonClicked(false)
            setSubmitClicked(false)
          }
        }
      } else if (response.status === 401) {
        await getNewAccessToken()
        return getRemmitanceReport(fromDate, toDate, requestId, branch)
      }
    } catch (error) {
      setSuccess(false)
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getRemmitanceReport(fromDate, toDate, requestId, branch)
      }
      toast.error(
        error?.response?.data?.message || 'Error fetching remittance report'
      )
    } finally {
      setLoading(false)
    }
  }

  const getBranch = async () => {
    try {
      if (!accessToken) {
        dispatch(logout())
        navigate('/login')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/branches`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      response.data = decryptData(response?.data?.cipher)
      if (response.status === 401) {
        await getNewAccessToken()
        return getBranch()
      }
      const data = response.data
      const modifiedArray = data?.data?.map(item => ({
        ...item,
        label: item.branchName,
        branchName: item.branchName,
      }))

      setBranches([
        {
          id: 'default',
          label: '--Select Branch--',
          branchName: '--Select Branch--',
        },
        ...modifiedArray,
      ])

      return modifiedArray
    } catch (error) {
      error.response.data = decryptData(error.response?.data?.cipher)
      if (error.response.status === 401) {
        await getNewAccessToken()
        return getBranch()
      }
      toast.error(error?.response?.data?.message || 'Error fetching branches')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRemmitanceReport()
    getReportsData()
    getBranch()
  }, [])
  const formik = useFormik({
    initialValues: {
      requestId: '',
      reportType: '',
      branch: '',
      toDate: '',
      fromDate: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const formattedToDate = formik.values.toDate
        ? moment(formik.values.toDate).format('YYYY-MM-DD')
        : ''

      const formattedFromDate = formik.values.fromDate
        ? moment(formik.values.fromDate).format('YYYY-MM-DD')
        : ''
      const formData = {
        requestId: formik.values.requestId,
        branch: formik.values.branch,
        toDate: formattedToDate,
        fromDate: formattedFromDate,
      }
      if (role !== 'superAdmin') {
        getRemmitanceReport(
          formData.fromDate,
          formData.toDate,
          formData.requestId,
          formData.branch
        )
      }
      if (role === 'superAdmin') {
        getReportsData(formData.fromDate, formData.toDate)
      }
      setIsFormChanged(false)
    },
  })

  useEffect(() => {
    setIsFormChanged(true) // Disable the download button on form change
  }, [formik.values])
  const handleStatus = val => {
    switch (val) {
      case 'created':
        return 'Created'
      case 'revert':
        return 'Reverted'
      case 'updated':
        return 'Updated'
      case 'forwarded':
        return 'Forwarded'
      case 'assignToRider':
        return 'Assign to rider'
      case 'handedOverToRider':
        return 'Handed over to rider'
      case 'riderCashPickedUp':
        return 'Rider picked up cash'
      case 'BVSPending':
        return 'BVS pending'
      case 'BVSSuccess':
        return 'BVS success'
      case 'BVSFailed':
        return 'BVS failed'
      case 'delivered':
        return 'Delivered'
      case 'beneficaryNotFound':
        return 'Beneficiary not found'
      case 'cashReceivedBack':
        return 'Cash received back'
      case 'revertedByTeller':
        return 'Reverted by teller'
      default:
        return val
    }
  }

  const handleDownloadReport = () => {
    const role = sessionStorage.getItem('role')
    const updatedData = data.map(item => {
      const mappedItem = {
        transactionReference: item?.transactionReference,
        mobileNumber: item?.beneficiaryContact,
        beneficiaryAddress: item?.homeAddress,
        beneficiaryBranch: item?.branchName,
        attempt: item?.attempt,
        remitterName: item?.remitterName,
        beneficiaryName: item?.beneficiaryName,
        requestStatus: handleStatus(item?.requestStatus),
        amount: item?.amount,
      }
      if (role === 'teller') {
        mappedItem.deliveryStatus =
          item.requestStatus === 'delivered' ? 'Delivered' : 'Pending' // show only in teller
      }
      // if (item.firstName && item.lastName) {
      //   mappedItem.maker = `${item.firstName} ${item.lastName}`
      // }

      return mappedItem
    })

    const userData = data.map(item => {
      const mappedItem = {
        employeeId: item?.employeeId,
        mobileNumber: item?.phone,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        branchName: item?.branch?.branchName,
        branchCode: item?.branch?.branchCode,
        city: item?.cityId?.name,
        status: item?.status === 1 ? 'Active' : 'Inactive',
        role: item?.role?.name,
      }

      return mappedItem
    })
    let worksheet
    if (role === 'superAdmin') {
      worksheet = XLSX.utils.json_to_sheet(userData)
    }
    if (role === 'maker' || role === 'checker' || role === 'teller') {
      worksheet = XLSX.utils.json_to_sheet(updatedData)
    }
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    const currentDate = new Date()
    const formattedDate = moment(currentDate).format('YYYY-MM-DD')
    let filename = 'report.xlsx'
    if (role === 'superAdmin') {
      filename = `AdminReport_${formattedDate}.xlsx`
    } else if (role === 'maker') {
      filename = `MakerReport_${formattedDate}.xlsx`
    } else if (role === 'checker') {
      filename = `CheckerReport_${formattedDate}.xlsx`
    } else if (role === 'teller') {
      filename = `TellerReport_${formattedDate}.xlsx`
    }
    XLSX.writeFile(workbook, filename)
    return
  }
  const columns = [
    role === 'superAdmin'
      ? { key: 'superAdminKey', value: 'Super Admin Report' }
      : null,
    role === 'maker' ? { key: 'makerKey', value: 'Maker Report' } : null,
    role === 'checker' ? { key: 'checkerKey', value: 'Checker Report' } : null,
    role === 'teller' ? { key: 'tellerKey', value: 'Teller Report' } : null,
  ].filter(column => column !== null)
  return (
    <DashboardLayout>
      <Box sx={{ m: '15px' }}>
        <Box>
          <Paper sx={{ borderRadius: '12px' }}>
            <Box
              bgcolor={'#CCCCCC'}
              sx={{ borderRadius: '12px 12px 0 0' }}
              paddingY="10px"
            >
              {' '}
              <Typography color="#074F93" variant="h4" marginLeft={'12px'}>
                Report Type
              </Typography>
            </Box>
            <form onSubmit={formik?.handleSubmit}>
              <Grid
                container
                align="center"
                alignItems={'center'}
                justify="center"
                marginY={'0px'}
                spacing={4}
                padding={mdUp ? '25px 15rem' : '5px 15px'}
              >
                <Grid item xs={12} md={12}>
                  <InputSelectFeild
                    name="reportType"
                    id="reportType"
                    textLabel={'Select Report Type'}
                    items={columns}
                    optionValue="key"
                    optionName="value"
                    value={formik?.values?.reportType || ''}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.reportType &&
                      Boolean(formik?.errors?.reportType)
                    }
                    helperText={
                      formik?.touched?.reportType && formik?.errors?.reportType
                    }
                  />
                </Grid>

                {role !== 'superAdmin' && (
                  <>
                    <Grid item xs={12} md={6}>
                      {' '}
                      <InputField
                        key={'1'}
                        id="requestId"
                        name="requestId"
                        type="text"
                        placeholder="Request Id"
                        label=""
                        textLabel="Request Id"
                        value={formik?.values.requestId || ''}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.requestId &&
                          Boolean(formik?.errors?.requestId)
                        }
                        helperText={
                          formik?.touched?.requestId &&
                          formik?.errors?.requestId
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        id="branch"
                        name="branch"
                        options={branches}
                        value={formik?.values.branch || ''}
                        sx={{ width: 300, borderRadius: '20px' }}
                        textLabel=" Select Branch"
                        data={branches}
                        setBranchId={id => {
                          formik?.setFieldValue('branch', id)
                          setBranchId(id)
                        }}
                        error={
                          formik?.touched?.branch &&
                          Boolean(formik?.errors?.branch)
                        }
                        helperText={
                          formik?.touched?.branch && formik?.errors?.branch
                        }
                      />
                    </Grid>
                  </>
                )}

                <>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={classes.inputField}
                        label="From Date"
                        style={{ borderRadius: '100px', width: '100%' }}
                        key={'1'}
                        id="fromDate"
                        placeholder="From Date"
                        textLabel=" From Date"
                        maxDate={dayjs(maxDate)}
                        value={formik?.values.fromDate || null}
                        // onChange={date => {
                        //   date = new Date(date)
                        //   setMinDate(date)
                        //   const formattedDate = format(date, 'yyyy-MM-dd')
                        //   formik?.setFieldValue('fromDate', formattedDate)
                        // }}
                        onChange={date => {
                          const selectedDate = dayjs(date) // Create a dayjs object
                          if (selectedDate.isValid()) {
                            setMinDate(selectedDate)
                            formik?.setFieldValue(
                              'fromDate',
                              selectedDate.format('YYYY-MM-DD')
                            )
                          } else {
                            formik?.setFieldValue('fromDate', '')
                          }
                        }}
                        onBlur={formik?.handleBlur('fromDate')}
                        error={
                          formik?.touched?.fromDate &&
                          Boolean(formik?.errors?.fromDate)
                        }
                        helperText={
                          formik?.touched?.fromDate && formik?.errors?.fromDate
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={classes.inputField}
                        label="To Date"
                        style={{ borderRadius: '100px', width: '100%' }}
                        key={'1'}
                        id="toDate"
                        placeholder="To Date"
                        textLabel=" To Date"
                        minDate={dayjs(minDate)}
                        value={formik?.values.toDate || null}
                        // onChange={date => {
                        //   date = new Date(date)
                        //   setMaxDate(date)
                        //   const formattedDate = format(date, 'yyyy-MM-dd')
                        //   formik?.setFieldValue('toDate', formattedDate)
                        // }}
                        onChange={date => {
                          const selectedDate = dayjs(date) // Create a dayjs object
                          if (selectedDate.isValid()) {
                            setMaxDate(selectedDate)
                            formik?.setFieldValue(
                              'toDate',
                              selectedDate.format('YYYY-MM-DD')
                            )
                          } else {
                            formik?.setFieldValue('toDate', '')
                          }
                        }}
                        onBlur={formik?.handleBlur('toDate')}
                        error={
                          formik?.touched?.toDate &&
                          Boolean(formik?.errors?.toDate)
                        }
                        helperText={
                          formik?.touched?.toDate && formik?.errors?.toDate
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              </Grid>
              <Grid container align="center" justify="center">
                <Grid item xs={12} marginY={'32px'}>
                  <InputButton
                    size={'small'}
                    sx={{
                      marginTop: '0',
                      padding: '0.5rem 2rem',
                      marginRight: '12px',
                    }}
                    // text={buttonText}
                    onClick={handleDownloadReport}
                    disabled={
                      buttonClicked || data?.length === 0
                        ? true
                        : false || isFormChanged
                    }
                    label="Download Report"
                  />
                  <InputButton
                    size={'small'}
                    sx={{
                      marginTop: '0',
                      padding: '0.5rem 2rem',
                      marginRight: '12px',
                    }}
                    // text={buttonText}
                    onClick={() => {
                      setSubmitClicked(true)
                    }}
                    type="submit"
                    label="Submit"
                    loading={loading}
                  />
                  {/* </Grid> */}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default Reports
